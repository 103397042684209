import React from 'react'
import { Box, capitalize } from '@mui/material'
import {
    RadioGroup,
    Avatar,
    FormLabel,
    Sheet,
    Textarea,
    Select,
    Option,
    Typography,
    Grid,
    Input,
    Button,
    Card,
    Checkbox,
    CardCover,
} from '@mui/joy'
import DoneIcon from '@mui/icons-material/Done'
import { colors } from '@/utilities/colors'
import { useCreatePropertyController } from '@/src/pages/secure/properties/create-property/hooks/use-create-property'
import {
    PropertySpaceType,
    PropertyType,
} from '@/src/pages/home/components/search-filter/types.ts'
import { Text } from '@/components/text'
import styles from '@/src/pages/secure/properties/create-property/components/steps/styles.module.css'

import TownHouseIcon from '@/assets/svgs/townhouse-icon.svg?react'
import HomeIcon from '@/assets/svgs/home.svg?react'
import HouseIcon from '@/assets/svgs/house-icon.svg?react'
import ApartmentIcon from '@/assets/svgs/apartment-icon.svg?react'
import { FontSize } from '@/components/text/types.ts'
import { TextInput } from '@/components/text-input'
import { Loading } from '@/src/pages/secure/secure-layout/components/loading'
import { SelectedAmenity } from '@/src/pages/secure/properties/create-property/components/steps/step-3/components/selected-amenity'
import { VirtualTourPaymentModal } from '@/src/pages/secure/properties/create-property/components/virtual-tour-payment'
import { RentalTerm } from '@/services/use-get-properties/types'
import { queryClient } from '@/utilities/react-query-client.ts'
import { keys } from '@/services/query-keys.ts'

export function Step3() {
    const { setActiveStep, property, images, onSubmit, isSubmitting } =
        useCreatePropertyController()
    const [showVirtualTourPaymentModal, setShowVirtualTourPaymentModal] =
        React.useState(false)

    function getResidentialIcon(name: string): React.ReactNode {
        if (name === 'townhouse') {
            return <TownHouseIcon style={{ height: 25, width: 25 }} />
        }

        if (name === 'house') {
            return <HouseIcon style={{ height: 25, width: 25 }} />
        }

        if (name === 'apartment') {
            return <ApartmentIcon style={{ height: 25, width: 25 }} />
        }
    }

    function getVirtualTourServiceFee(): number {
        let fee = 0
        if (property.rental_term === RentalTerm.DAILY) {
            fee = property.price * 30 * 0.02
            return fee
        }

        if (property.rental_term === RentalTerm.MONTHLY) {
            fee = property.price * 0.02
            return fee
        }

        /*  if (property.rental_term === RentalTerm.YEARLY) {
            fee = (property.price / 12) * 0.02
            return fee
        } */

        return fee
    }

    function onSuccess() {
        setShowVirtualTourPaymentModal(false)
        onSubmit()
        return void queryClient.invalidateQueries({
            queryKey: [keys.GET_USER.key],
        })
    }

    function publishProperty() {
        if (property.has_virtual_tour) {
            setShowVirtualTourPaymentModal(true)
            return
        }

        onSubmit()
    }

    if (isSubmitting) {
        return (
            <Loading
                text={'Creating property, dont refresh or reload the page'}
            />
        )
    }

    return (
        <React.Fragment>
            <VirtualTourPaymentModal
                show={showVirtualTourPaymentModal}
                setShow={setShowVirtualTourPaymentModal}
                amount={getVirtualTourServiceFee()}
                onSuccess={onSuccess}
            />
            <Typography
                sx={{ color: colors.brown, mb: '2rem' }}
                level="title-md"
                fontSize={20}
            >
                Step 3: Review and publish
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <Box>
                    <Text textFontSize={FontSize.Title}>
                        What describes your property?
                    </Text>
                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <RadioGroup
                            aria-label="platform"
                            value={property.property_types}
                            overlay
                            name="property_types"
                        >
                            <Sheet
                                variant="outlined"
                                sx={{
                                    borderRadius: 'md',
                                    boxShadow: 'sm',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1.5,
                                    p: 2,
                                    minWidth: 120,
                                }}
                            >
                                <Avatar variant="soft" size="sm">
                                    <HomeIcon />
                                </Avatar>
                                <FormLabel>
                                    {capitalize(property.property_types)}
                                </FormLabel>
                            </Sheet>
                        </RadioGroup>
                    </Box>
                </Box>
                {property.property_types === PropertyType.RESIDENTIAL && (
                    <Box>
                        <Text
                            textFontSize={FontSize.Title}
                            sx={{ color: colors.brown, mb: 2 }}
                        >
                            Specify the type of property{' '}
                        </Text>
                        <RadioGroup
                            aria-label="platform"
                            defaultValue="Website"
                            overlay
                            name="residential_type"
                            sx={{
                                maxWidth: '8rem',
                            }}
                        >
                            <Sheet
                                variant="outlined"
                                sx={{
                                    borderRadius: 'md',
                                    boxShadow: 'sm',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1.5,
                                    p: 2,
                                    minWidth: 120,
                                }}
                            >
                                <Avatar variant="soft" size="sm">
                                    {getResidentialIcon(
                                        property.residential_type
                                    )}
                                </Avatar>
                                <FormLabel htmlFor={property.residential_type}>
                                    {property.residential_type}
                                </FormLabel>
                            </Sheet>
                        </RadioGroup>
                    </Box>
                )}

                <Box>
                    <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                        Space Type Choosed
                    </Text>
                    <Box
                        className={styles.more_details_item}
                        sx={{
                            boxShadow: 'sm',
                            backgroundColor: colors.white,
                            maxWidth: '3rem',
                            py: 1.5,
                        }}
                    >
                        <Typography>
                            {property.property_space.valueOf() ===
                            PropertySpaceType.ENTIRE_SPACE.valueOf()
                                ? 'Entire Space'
                                : 'Shared Space'}
                        </Typography>
                        <DoneIcon />
                    </Box>
                </Box>

                <Box>
                    <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                        In need of a virtual tour?
                    </Text>
                    <Checkbox
                        name={'has_virtual_tour'}
                        label="Virtual tour"
                        defaultChecked={property.has_virtual_tour}
                        color="warning"
                        disabled={true}
                    />
                </Box>
                <Box>
                    <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                        Write a brief description of your property
                    </Text>
                    <Textarea
                        minRows={5}
                        name="overview"
                        value={property.overview}
                        disabled
                    />
                </Box>
                <Box>
                    <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                        Where is your property located?
                    </Text>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography>Region</Typography>
                            <Input
                                name="neighborhood"
                                value={property.region.region_name}
                                size="md"
                                sx={{ borderRadius: '0.5rem' }}
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography>Neighborhood</Typography>
                            <Input
                                name="neighborhood"
                                value={
                                    property.neighbourhood.neighbourhood_name
                                }
                                size="md"
                                sx={{ borderRadius: '0.5rem' }}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography>Address 1</Typography>
                            <TextInput
                                size={'md'}
                                name={'address1'}
                                value={property.address1}
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography>Address 2</Typography>
                            <TextInput
                                size={'md'}
                                name={'address2'}
                                value={property.address2}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography
                        className={styles.step_sub_header}
                        sx={{ color: colors.brown }}
                    >
                        Share some details about your property
                    </Typography>
                    <Box
                        sx={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}
                    >
                        <Box
                            className={styles.more_details_item}
                            sx={{ border: `0.08rem solid ${colors.lightBlue}` }}
                        >
                            <Typography sx={{ color: colors.brown }}>
                                Bedroom
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{ fontSize: '1.2rem', fontWeight: 600 }}
                                >
                                    {property.number_of_bedrooms}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            className={styles.more_details_item}
                            sx={{ border: `0.08rem solid ${colors.lightBlue}` }}
                        >
                            <Typography sx={{ color: colors.brown }}>
                                Baths
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{ fontSize: '1.2rem', fontWeight: 600 }}
                                >
                                    {property.number_of_bathrooms}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            className={styles.more_details_item}
                            sx={{ border: `0.08rem solid ${colors.lightBlue}` }}
                        >
                            <Box sx={{ color: colors.brown, display: 'block' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                    Packing Space
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '0.65rem',
                                    }}
                                >
                                    (max no. of cars)
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{ fontSize: '1.2rem', fontWeight: 600 }}
                                >
                                    {property.number_of_packing_space}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid md={4} xs={12}>
                        <Box>
                            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                                Property readiness status
                            </Text>
                            <Select
                                disabled
                                name="property_information"
                                value={property.property_information}
                                size="lg"
                            >
                                <Option value={property.property_information}>
                                    {property.property_information
                                        .split('_')
                                        .join(' ')}
                                </Option>
                            </Select>
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12}>
                        <Box>
                            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                                What year was the property built?
                            </Text>
                            <Select
                                disabled
                                size="lg"
                                sx={{ maxWidth: '10rem' }}
                                value={property.year_built}
                            >
                                <Option value={property.year_built}>
                                    {property.year_built}
                                </Option>
                            </Select>
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12}>
                        <Box>
                            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                                Advance
                            </Text>
                            <Select
                                placeholder="Select advance"
                                // sx={{ maxWidth: '10rem' }}
                                name="rental_duration"
                                size="lg"
                                value={property.rental_duration}
                            >
                                <Option value={property.rental_duration}>
                                    {property.rental_duration} months
                                </Option>
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid md={4}>
                        <Text textFontSize={FontSize.Title}>Price</Text>
                        <TextInput
                            name="price"
                            type={'number'}
                            size="lg"
                            showCurrency
                            value={property.price}
                            disabled
                        />
                    </Grid>
                    <Grid md={4}>
                        <Text textFontSize={FontSize.Title}>Rental term</Text>
                        <TextInput
                            name="rental_term"
                            type={'text'}
                            size="lg"
                            disabled
                            value={property.rental_term}
                        />
                    </Grid>
                    <Grid md={4}>
                        <Text textFontSize={FontSize.Title}>
                            Security deposit
                        </Text>
                        <TextInput
                            name="security_deposit"
                            type={'number'}
                            size="lg"
                            showCurrency
                            value={property.security_deposit}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={4}>
                        <Text textFontSize={FontSize.Title}>
                            Service charge
                        </Text>
                        <TextInput
                            type={'number'}
                            placeholder={'Service charge'}
                            size="lg"
                            showCurrency
                            name="service_charge"
                            disabled
                            value={property.service_charge}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                {property.selected_general_amenities.length > 0 && (
                    <SelectedAmenity
                        label={'General Amenities'}
                        amenities={property.selected_general_amenities}
                    />
                )}
                {property.selected_kitchen_amenities.length > 0 && (
                    <SelectedAmenity
                        label={'Kitchen Amenities'}
                        amenities={property.selected_kitchen_amenities}
                    />
                )}
                {property.selected_bathroom_amenities.length > 0 && (
                    <SelectedAmenity
                        label={'Bathroom Amenities'}
                        amenities={property.selected_bathroom_amenities}
                    />
                )}
                {property.selected_bedroom_amenities.length > 0 && (
                    <SelectedAmenity
                        label={'Bedroom Amenities'}
                        amenities={property.selected_bedroom_amenities}
                    />
                )}
                {property.selected_facility_amenities.length > 0 && (
                    <SelectedAmenity
                        label={'Facility Amenities'}
                        amenities={property.selected_facility_amenities}
                    />
                )}
                {property.selected_outdoor_amenities.length > 0 && (
                    <SelectedAmenity
                        label={'Outdoor Amenities'}
                        amenities={property.selected_outdoor_amenities}
                    />
                )}
                {property.selected_livingroom_amenities.length > 0 && (
                    <SelectedAmenity
                        label={'Living Room Amenities'}
                        amenities={property.selected_livingroom_amenities}
                    />
                )}
            </Box>

            <Box>
                <Grid container spacing={3} my={4}>
                    {images.map((image, _index) => (
                        <Grid key={_index} xs={12} sm={6} md={4} lg={3}>
                            <Card
                                sx={{
                                    minHeight: 200,
                                    flexGrow: 1,
                                    border: image.is_cover
                                        ? `2px Solid ${colors.primary}`
                                        : '',
                                }}
                            >
                                <CardCover>
                                    <img
                                        src={image.src}
                                        alt="Uploaded"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </CardCover>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '2rem',
                }}
            >
                <Button
                    onClick={() => setActiveStep(0)}
                    sx={{
                        backgroundColor: colors.white,
                        color: colors.primary,
                        border: `1px solid ${colors.primary}`,
                        '&:hover': {
                            color: colors.white,
                        },
                    }}
                >
                    Edit
                </Button>
                <Button onClick={publishProperty}>Publish</Button>
            </Box>
        </React.Fragment>
    )
}
