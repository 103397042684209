import React from 'react'
import { Header } from '@/src/components/header'
import { Footer } from '@/src/components/footer'
import { PropertyList } from '@/src/pages/home/components/property-list'
import { HowOpenPropsCanHelp } from '@/src/pages/home/components/how-open-props-can-help/'
import { Subscription } from '@/src/pages/home/components/subscription'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import analytics from '@/utilities/analytics'
import { Box } from '@mui/joy'
import { VirtualTourSection } from '@/src/pages/home/components/virtual-tour-section'

export function Home() {
    const userQuery = useGetAuthenticatedUser()
    React.useEffect(() => {
        if (userQuery.user) {
            analytics.identify(userQuery.user.id, {
                user_id: userQuery.user.id,
                email: userQuery.user.email,
                first_name: userQuery.user.first_name,
            })
        }
    }, [])

    return (
        <React.Fragment>
            <Header />
            <PropertyList />
            {/* <CityList /> */}
            <VirtualTourSection />
            {!userQuery.isSubscriptionActive && userQuery.viewCount < 1 ? (
                <Subscription />
            ) : (
                <Box my={3} />
            )}
            <HowOpenPropsCanHelp />
            <Footer />
        </React.Fragment>
    )
}
