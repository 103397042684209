import * as React from "react";
const SvgLocation = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 35 50", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0625,0,0,0.0625,-7.6484,-5.32907e-15)" }, /* @__PURE__ */ React.createElement("path", { d: "M399.988,0C246.91,0 122.374,124.554 122.374,277.636C122.374,424.99 374.258,769.262 384.983,783.841L394.992,797.463C396.163,799.064 398.027,800 399.988,800C401.979,800 403.831,799.064 405.014,797.463L415.017,783.841C425.748,769.262 677.626,424.99 677.626,277.636C677.626,124.554 553.07,0 399.988,0ZM399.988,178.187C454.836,178.187 499.436,222.789 499.436,277.636C499.436,332.454 454.834,377.084 399.988,377.084C345.172,377.084 300.539,332.454 300.539,277.636C300.539,222.789 345.17,178.187 399.988,178.187Z", style: {
  fillRule: "nonzero"
} })));
export default SvgLocation;
