import React from 'react'
import { Box } from '@mui/material'
import { useFormik } from 'formik'
import { Select, Option, Typography, Grid, Button } from '@mui/joy'
import { colors } from '@/utilities/colors'
import { useCreatePropertyController } from '@/src/pages/secure/properties/create-property/hooks/use-create-property'
import { useGetPropertiesLocationsFormatPlain } from '@/services/use-get-properties-locations'
import { useGetAmenities } from '@/services/use-get-amenities'
import {
    PropertyType,
    // PurposeOfListingType,
} from '@/src/pages/home/components/search-filter/types.ts'
import { getListOfYears } from '@/utilities/dates'
import { Text } from '@/components/text'
// import styles from '@/src/pages/secure/properties/create-property/components/steps/styles.module.css'
import { FontSize } from '@/components/text/types.ts'
// import RemoveIcon from '@mui/icons-material/Remove'
// import AddIcon from '@mui/icons-material/Add'
import { AmenitySelector } from '@/src/pages/secure/properties/components/amenity-selector'
import { TextInput } from '@/components/text-input'
import { PropertySpace } from '@/src/pages/secure/properties/components/property-space'
import { PropertyTypes } from '@/src/pages/secure/properties/components/property-type'
import { VirtualTourSelect } from '@/src/pages/secure/properties/components/virtual-tour'
import { PropertyOverview } from '@/src/pages/secure/properties/components/property-overview'
import { RegionSelector } from '@/src/pages/secure/properties/components/property-location/region'
import { NeighbourhoodSelector } from '@/src/pages/secure/properties/components/property-location/neighbourhood'
import { AddressInput } from '@/src/pages/secure/properties/components/property-location/address'
import { LocatePropertyOnMap } from '@/src/pages/secure/properties/components/property-location/map'

import { step1Schema } from '@/src/pages/secure/properties/create-property/components/steps/step-1/schema'
import {
    PropertyInformation,
    RentalTerm,
} from '@/services/use-get-properties/types.ts'
import { MetaTags } from '@/components/meta-tags'
import { RENTAL_DURATION } from '@/utilities/property.tsx'
// import { PurposeOfListing } from '@/src/pages/secure/properties/components/purpose-of-listing'

export type Neighborhood = {
    id: string
    neighbourhood_name: string
    coords: {
        type: string
        coordinates: number[]
    }
}

export function Step1() {
    const { setActiveStep, property, setProperty } =
        useCreatePropertyController()

    const amenities = useGetAmenities()
    const [neighborhoods, setNeighborhoods] = React.useState<Neighborhood[]>([])
    const formik = useFormik({
        initialValues: {
            residential_type: property.residential_type,
            price: property.price,
            security_deposit: property.security_deposit,
            service_charge: property.service_charge,
            rental_term: property.rental_term,
            rental_duration: property.rental_duration,
            property_types: property.property_types || PropertyType.UNKNOWN,
            property_space: property.property_space || '',
            has_virtual_tour: property.has_virtual_tour,
            year_built: property.year_built,
            region: property.region,
            property_land_size: property.property_land_size,
            neighbourhood: property.neighbourhood,
            overview: property.overview,
            number_of_bedrooms: property.number_of_bedrooms,
            number_of_bathrooms: property.number_of_bathrooms,
            number_of_packing_space: property.number_of_packing_space,
            selected_general_amenities: property.selected_general_amenities,
            selected_kitchen_amenities: property.selected_kitchen_amenities,
            selected_bedroom_amenities: property.selected_bedroom_amenities,
            selected_facility_amenities: property.selected_facility_amenities,
            selected_outdoor_amenities: property.selected_outdoor_amenities,
            selected_bathroom_amenities: property.selected_bathroom_amenities,
            selected_livingroom_amenities:
                property.selected_livingroom_amenities,
            property_information: property.property_information,
            address1: property.address1,
            address2: property.address2,
            coords: property.coords || { type: 'Point', coordinates: [0, 0] },
            // purpose_of_listing: property.purpose_of_listing,
            // price_per_night: property.price_per_night,
            // min_number_of_days: property.min_number_of_days,
            // max_number_of_days: property.max_number_of_days,
            // min_guest_allowed: property.min_guest_allowed,
            // max_guest_allowed: property.max_guest_allowed,
            // min_number_of_nights: property.min_number_of_nights,
            // max_number_of_nights: property.max_number_of_nights,
        },
        validationSchema: step1Schema,
        onSubmit: () => {
            submitStep()
            setActiveStep(1)
        },
    })

    const locationsQuery = useGetPropertiesLocationsFormatPlain()
    const locations = locationsQuery.data || []

    function submitStep() {
        setProperty((prevProperty) => ({
            ...prevProperty,
            residential_type: formik.values.residential_type,
            price: formik.values.price,
            service_charge: formik.values.service_charge,
            security_deposit: formik.values.security_deposit,
            property_types: formik.values.property_types,
            property_space: formik.values.property_space,
            rental_term: formik.values.rental_term,
            rental_duration: formik.values.rental_duration,
            selectedTags: [],
            has_virtual_tour: formik.values.has_virtual_tour,
            year_built: formik.values.year_built,
            region: formik.values.region,
            property_land_size: formik.values.property_land_size,
            neighbourhood: formik.values.neighbourhood,
            overview: formik.values.overview,
            number_of_bedrooms: formik.values.number_of_bedrooms,
            number_of_bathrooms: formik.values.number_of_bathrooms,
            number_of_packing_space: formik.values.number_of_packing_space,
            selected_general_amenities:
                formik.values.selected_general_amenities,
            selected_kitchen_amenities:
                formik.values.selected_kitchen_amenities,
            selected_bedroom_amenities:
                formik.values.selected_bedroom_amenities,
            selected_facility_amenities:
                formik.values.selected_facility_amenities,
            selected_outdoor_amenities:
                formik.values.selected_outdoor_amenities,
            selected_bathroom_amenities:
                formik.values.selected_bathroom_amenities,
            selected_livingroom_amenities:
                formik.values.selected_livingroom_amenities,
            property_information: formik.values.property_information,
            address1: formik.values.address1,
            address2: formik.values.address2,
            coords: formik.values.coords,
            // purpose_of_listing: formik.values.purpose_of_listing,
            // price_per_night: formik.values.price_per_night,
            // min_number_of_days: formik.values.min_number_of_days,
            // max_number_of_days: formik.values.max_number_of_days,
            // min_guest_allowed: formik.values.min_guest_allowed,
            // max_guest_allowed: formik.values.max_guest_allowed,
            // min_number_of_nights: formik.values.min_number_of_nights,
            // max_number_of_nights: formik.values.max_number_of_nights,
        }))
    }

    function handleRegionChange(id: string) {
        // Find the selected region object from the regions array
        const selectedRegionObject = getRegionFromId(id)
        const neighborhoodNames =
            selectedRegionObject?.neighbourhoods.map(
                (neighborhood) => neighborhood
            ) || []

        // Set the neighborhoods for the selected region in the state
        setNeighborhoods(neighborhoodNames)
        const reg = getRegionFromId(id)
        void formik.setFieldValue('region', reg)
        void formik.setFieldTouched('region', true)
    }

    function handleNeighbourhoodChange(id: string) {
        const neighbourhood = getNeighbourFromId(id, neighborhoods) ?? {
            id: '',
            neighbourhood_name: '',
            coords: {
                type: 'Point',
                coordinates: [0, 0],
            },
        }
        void formik.setFieldValue('neighbourhood', neighbourhood)
    }

    function getNeighbourFromId(id: string, neighbourhoods: Neighborhood[]) {
        return neighbourhoods.find((ne) => ne.id === id)
    }

    function getRegionFromId(id: string) {
        return (
            (locations || []).find((region) => region.id === id) || {
                id: '',
                region_name: '',
                neighbourhoods: [],
            }
        )
    }

    const years = React.useMemo(() => getListOfYears(1900), [])
    const [clickedCoords, setClickedCoords] = React.useState<{
        lat: number
        lng: number
    } | null>(null)

    function handleClickMap(e: google.maps.MapMouseEvent) {
        const lat = e.latLng?.lat()
        const lng = e.latLng?.lng()
        if (lat === undefined || lng === undefined) {
            console.error('Latitude or longitude is undefined')
            return
        }
        formik.setFieldValue('coords', {
            type: 'Point',
            coordinates: [lat, lng],
        })
        setClickedCoords({ lat, lng })
    }

    // function getPropertySpaceOptions() {
    //     if (formik.values.purpose_of_listing === PurposeOfListingType.RENT) {
    //         return [
    //             { name: 'Shared Space', value: 'shared_space' },
    //             { name: 'Entire Space', value: 'entire_space' },
    //         ]
    //     }
    //     if (
    //         formik.values.purpose_of_listing === PurposeOfListingType.SHORT_STAY
    //     ) {
    //         return [
    //             { name: 'Entire space', value: 'entire_space' },
    //             { name: 'Private room', value: 'private_room' },
    //         ]
    //     }
    //     if (formik.values.purpose_of_listing === PurposeOfListingType.SELL) {
    //         return [
    //             { name: 'House', value: 'house' },
    //             { name: 'Private room', value: 'private_room' },
    //         ]
    //     }
    // }

    return (
        <React.Fragment>
            <MetaTags
                title={'Openprops: Create property'}
                description={'Create property'}
            />
            <Typography
                level="title-sm"
                fontSize={'2.5rem'}
                fontWeight={600}
                textAlign={'center'}
                mb={3}
                sx={{
                    color: colors.brown,
                }}
            >
                Get started on Openprop
            </Typography>
            <Typography
                sx={{ color: colors.brown, mb: '2rem' }}
                level="title-md"
                fontSize={20}
            >
                Step 1: Give basic info about your property
            </Typography>
            <Box display={'flex'} flexDirection={'column'} sx={{ gap: '2rem' }}>
                {/*<PurposeOfListing*/}
                {/*    handleChange={formik.handleChange}*/}
                {/*    purpose_of_listing={formik.values.purpose_of_listing}*/}
                {/*    error={formik.errors.purpose_of_listing}*/}
                {/*    touched={!!formik.touched.purpose_of_listing}*/}
                {/*/>*/}
                <PropertyTypes
                    property_type={formik.values.property_types}
                    residential_type={formik.values.residential_type}
                    handleChange={formik.handleChange}
                    error={formik.errors.residential_type || ''}
                    touched={!!formik.touched.residential_type}
                />
                <PropertySpace
                    property_space={formik.values.property_space}
                    handleChange={formik.handleChange}
                    touched={!!formik.touched.property_space}
                    error={formik.errors.property_space || ''}
                    // options={getPropertySpaceOptions() || []}
                    options={[
                        { name: 'Shared Space', value: 'shared_space' },
                        { name: 'Entire Space', value: 'entire_space' },
                    ]}
                />
                <VirtualTourSelect
                    has_virtual_tour={formik.values.has_virtual_tour}
                    handleChange={formik.handleChange}
                />
                <PropertyOverview
                    overview={formik.values.overview}
                    touched={!!formik.touched.overview}
                    error={formik.errors.overview || ''}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                />
                <Box>
                    <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                        Where is your property located?
                    </Text>
                    <Grid container spacing={4} mb={2}>
                        <Grid md={4} xs={12}>
                            <RegionSelector
                                region_id={formik.values.region.id}
                                handleRegionChange={handleRegionChange}
                            />
                        </Grid>
                        <Grid md={4} xs={12}>
                            <NeighbourhoodSelector
                                neighbourhood_id={
                                    formik.values.neighbourhood.id
                                }
                                handleNeighbourhoodChange={
                                    handleNeighbourhoodChange
                                }
                                touched={!!formik.touched.neighbourhood}
                                error={formik.errors.neighbourhood}
                                setClickedCoords={setClickedCoords}
                                neighbourhoods={neighborhoods}
                            />
                        </Grid>
                    </Grid>
                    <AddressInput
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        address1={formik.values.address1}
                        address2={formik.values.address2}
                        is_address1_touched={!!formik.touched.address1}
                        is_address2_touched={!!formik.touched.address2}
                        address1_error={formik.errors.address1}
                        address2_error={formik.errors.address2}
                    />
                </Box>
                <LocatePropertyOnMap
                    clickedCoords={clickedCoords}
                    handleClickMap={handleClickMap}
                />
                {/*<Box>*/}
                {/*    {formik.values.purpose_of_listing ===*/}
                {/*        PurposeOfListingType.SHORT_STAY && (*/}
                {/*        <Box*/}
                {/*            sx={{*/}
                {/*                display: 'flex',*/}
                {/*                flexDirection: 'column',*/}
                {/*                gap: '1.5rem',*/}
                {/*                mb: 3,*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <Box>*/}
                {/*                <Text textFontSize={FontSize.Title}>*/}
                {/*                    Stay duration*/}
                {/*                </Text>*/}
                {/*                <Box>date range picker goes here</Box>*/}
                {/*            </Box>*/}
                {/*            <Box mb={-2}>*/}
                {/*                <Text textFontSize={FontSize.Title} mb={2}>*/}
                {/*                    Number of days*/}
                {/*                </Text>*/}
                {/*                <Grid*/}
                {/*                    container*/}
                {/*                    spacing={{ md: 4, xs: 0.5 }}*/}
                {/*                    gap={1}*/}
                {/*                >*/}
                {/*                    <Grid md={4} xs={12}>*/}
                {/*                        <TextInput*/}
                {/*                            placeholder={'Min days'}*/}
                {/*                            size={'lg'}*/}
                {/*                            errorText={*/}
                {/*                                formik.touched*/}
                {/*                                    .min_number_of_days &&*/}
                {/*                                formik.errors*/}
                {/*                                    ?.min_number_of_days*/}
                {/*                                    ? formik.errors*/}
                {/*                                          .min_number_of_days*/}
                {/*                                    : undefined*/}
                {/*                            }*/}
                {/*                            type="number"*/}
                {/*                            name="min_number_of_days"*/}
                {/*                            value={*/}
                {/*                                formik.values.min_number_of_days*/}
                {/*                            }*/}
                {/*                            onBlur={formik.handleBlur}*/}
                {/*                            onChange={formik.handleChange}*/}
                {/*                        />*/}
                {/*                    </Grid>*/}
                {/*                    <Grid md={4} xs={12}>*/}
                {/*                        <TextInput*/}
                {/*                            placeholder={'Max days'}*/}
                {/*                            size={'lg'}*/}
                {/*                            errorText={*/}
                {/*                                formik.touched*/}
                {/*                                    .max_number_of_days &&*/}
                {/*                                formik.errors*/}
                {/*                                    ?.max_number_of_days*/}
                {/*                                    ? formik.errors*/}
                {/*                                          .max_number_of_days*/}
                {/*                                    : undefined*/}
                {/*                            }*/}
                {/*                            type="number"*/}
                {/*                            name="max_number_of_days"*/}
                {/*                            value={*/}
                {/*                                formik.values.max_number_of_days*/}
                {/*                            }*/}
                {/*                            onBlur={formik.handleBlur}*/}
                {/*                            onChange={formik.handleChange}*/}
                {/*                        />*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </Box>*/}
                {/*            <Box mb={-2}>*/}
                {/*                <Text textFontSize={FontSize.Title} mb={2}>*/}
                {/*                    Guests allowed*/}
                {/*                </Text>*/}
                {/*                <Grid*/}
                {/*                    container*/}
                {/*                    spacing={{ md: 4, xs: 0.5 }}*/}
                {/*                    gap={1}*/}
                {/*                >*/}
                {/*                    <Grid md={4} xs={12}>*/}
                {/*                        <TextInput*/}
                {/*                            placeholder={'Min guests'}*/}
                {/*                            size={'lg'}*/}
                {/*                            errorText={*/}
                {/*                                formik.touched*/}
                {/*                                    .min_guest_allowed &&*/}
                {/*                                formik.errors?.min_guest_allowed*/}
                {/*                                    ? formik.errors*/}
                {/*                                          .min_guest_allowed*/}
                {/*                                    : undefined*/}
                {/*                            }*/}
                {/*                            type="number"*/}
                {/*                            name="min_guest_allowed"*/}
                {/*                            value={*/}
                {/*                                formik.values.min_guest_allowed*/}
                {/*                            }*/}
                {/*                            onBlur={formik.handleBlur}*/}
                {/*                            onChange={formik.handleChange}*/}
                {/*                        />*/}
                {/*                    </Grid>*/}
                {/*                    <Grid md={4} xs={12}>*/}
                {/*                        <TextInput*/}
                {/*                            placeholder={'Max guests'}*/}
                {/*                            size={'lg'}*/}
                {/*                            errorText={*/}
                {/*                                formik.touched*/}
                {/*                                    .max_guest_allowed &&*/}
                {/*                                formik.errors?.max_guest_allowed*/}
                {/*                                    ? formik.errors*/}
                {/*                                          .max_guest_allowed*/}
                {/*                                    : undefined*/}
                {/*                            }*/}
                {/*                            type="number"*/}
                {/*                            name="max_guest_allowed"*/}
                {/*                            value={*/}
                {/*                                formik.values.max_guest_allowed*/}
                {/*                            }*/}
                {/*                            onBlur={formik.handleBlur}*/}
                {/*                            onChange={formik.handleChange}*/}
                {/*                        />*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </Box>*/}
                {/*            <Box mb={-2}>*/}
                {/*                <Text textFontSize={FontSize.Title} mb={2}>*/}
                {/*                    Number of nights*/}
                {/*                </Text>*/}
                {/*                <Grid*/}
                {/*                    container*/}
                {/*                    spacing={{ md: 4, xs: 0.5 }}*/}
                {/*                    gap={1}*/}
                {/*                >*/}
                {/*                    <Grid md={4} xs={12}>*/}
                {/*                        <TextInput*/}
                {/*                            placeholder={'Min nights'}*/}
                {/*                            size={'lg'}*/}
                {/*                            errorText={*/}
                {/*                                formik.touched*/}
                {/*                                    .min_number_of_nights &&*/}
                {/*                                formik.errors*/}
                {/*                                    ?.min_number_of_nights*/}
                {/*                                    ? formik.errors*/}
                {/*                                          .min_number_of_nights*/}
                {/*                                    : undefined*/}
                {/*                            }*/}
                {/*                            type="number"*/}
                {/*                            name="min_number_of_nights"*/}
                {/*                            value={*/}
                {/*                                formik.values*/}
                {/*                                    .min_number_of_nights*/}
                {/*                            }*/}
                {/*                            onBlur={formik.handleBlur}*/}
                {/*                            onChange={formik.handleChange}*/}
                {/*                        />*/}
                {/*                    </Grid>*/}
                {/*                    <Grid md={4} xs={12}>*/}
                {/*                        <TextInput*/}
                {/*                            placeholder={'Max nights'}*/}
                {/*                            size={'lg'}*/}
                {/*                            errorText={*/}
                {/*                                formik.touched*/}
                {/*                                    .max_number_of_nights &&*/}
                {/*                                formik.errors*/}
                {/*                                    ?.max_number_of_nights*/}
                {/*                                    ? formik.errors*/}
                {/*                                          .max_number_of_nights*/}
                {/*                                    : undefined*/}
                {/*                            }*/}
                {/*                            type="number"*/}
                {/*                            name="max_number_of_nights"*/}
                {/*                            value={*/}
                {/*                                formik.values*/}
                {/*                                    .max_number_of_nights*/}
                {/*                            }*/}
                {/*                            onBlur={formik.handleBlur}*/}
                {/*                            onChange={formik.handleChange}*/}
                {/*                        />*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*    )}*/}
                {/*    <Typography*/}
                {/*        className={styles.step_sub_header}*/}
                {/*        sx={{ color: colors.brown }}*/}
                {/*    >*/}
                {/*        Share some details about your property*/}
                {/*    </Typography>*/}
                {/*    <Box*/}
                {/*        sx={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}*/}
                {/*    >*/}
                {/*        <Box*/}
                {/*            className={styles.more_details_item}*/}
                {/*            sx={{ border: `0.08rem solid ${colors.lightBlue}` }}*/}
                {/*        >*/}
                {/*            <Typography sx={{ color: colors.brown }}>*/}
                {/*                Bedroom*/}
                {/*            </Typography>*/}
                {/*            <Box*/}
                {/*                display={'flex'}*/}
                {/*                gap={'1rem'}*/}
                {/*                alignItems={'center'}*/}
                {/*            >*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        border: `0.08rem solid ${colors.lightBlue}`,*/}
                {/*                    }}*/}
                {/*                    className={`${styles.circle} ${*/}
                {/*                        formik.values.number_of_bedrooms < 1*/}
                {/*                            ? styles.disabled_circle*/}
                {/*                            : ''*/}
                {/*                    }`}*/}
                {/*                    onClick={() => {*/}
                {/*                        if (*/}
                {/*                            formik.values.number_of_bedrooms > 0*/}
                {/*                        ) {*/}
                {/*                            void formik.setFieldValue(*/}
                {/*                                'number_of_bedrooms',*/}
                {/*                                formik.values*/}
                {/*                                    .number_of_bedrooms - 1*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <RemoveIcon fontSize="medium" />*/}
                {/*                </Box>*/}
                {/*                <Typography*/}
                {/*                    sx={{ fontSize: '1.2rem', fontWeight: 600 }}*/}
                {/*                >*/}
                {/*                    {formik.values.number_of_bedrooms}*/}
                {/*                </Typography>*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        border: `0.08rem solid ${colors.lightBlue}`,*/}
                {/*                    }}*/}
                {/*                    className={`${styles.circle} ${*/}
                {/*                        formik.values.number_of_bedrooms > 19*/}
                {/*                            ? styles.disabled_circle*/}
                {/*                            : ''*/}
                {/*                    }`}*/}
                {/*                    onClick={() => {*/}
                {/*                        if (*/}
                {/*                            formik.values.number_of_bedrooms <*/}
                {/*                            20*/}
                {/*                        ) {*/}
                {/*                            void formik.setFieldValue(*/}
                {/*                                'number_of_bedrooms',*/}
                {/*                                formik.values*/}
                {/*                                    .number_of_bedrooms + 1*/}
                {/*                            )*/}
                {/*                            void formik.setFieldTouched(*/}
                {/*                                'number_of_bedrooms',*/}
                {/*                                true*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <AddIcon fontSize="medium" />*/}
                {/*                </Box>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*        <Box*/}
                {/*            className={styles.more_details_item}*/}
                {/*            sx={{ border: `0.08rem solid ${colors.lightBlue}` }}*/}
                {/*        >*/}
                {/*            <Typography sx={{ color: colors.brown }}>*/}
                {/*                Baths*/}
                {/*            </Typography>*/}
                {/*            <Box*/}
                {/*                display={'flex'}*/}
                {/*                gap={'1rem'}*/}
                {/*                alignItems={'center'}*/}
                {/*            >*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        border: `0.08rem solid ${colors.lightBlue}`,*/}
                {/*                    }}*/}
                {/*                    className={`${styles.circle} ${*/}
                {/*                        formik.values.number_of_bathrooms < 1*/}
                {/*                            ? styles.disabled_circle*/}
                {/*                            : ''*/}
                {/*                    }`}*/}
                {/*                    onClick={() => {*/}
                {/*                        if (*/}
                {/*                            formik.values.number_of_bathrooms >*/}
                {/*                            0*/}
                {/*                        ) {*/}
                {/*                            void formik.setFieldValue(*/}
                {/*                                'number_of_bathrooms',*/}
                {/*                                formik.values*/}
                {/*                                    .number_of_bathrooms - 1*/}
                {/*                            )*/}
                {/*                            void formik.setFieldTouched(*/}
                {/*                                'number_of_bathrooms',*/}
                {/*                                true*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <RemoveIcon fontSize="medium" />*/}
                {/*                </Box>*/}
                {/*                <Typography*/}
                {/*                    sx={{ fontSize: '1.2rem', fontWeight: 600 }}*/}
                {/*                >*/}
                {/*                    {formik.values.number_of_bathrooms}*/}
                {/*                </Typography>*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        border: `0.08rem solid ${colors.lightBlue}`,*/}
                {/*                    }}*/}
                {/*                    className={`${styles.circle} ${*/}
                {/*                        formik.values.number_of_bathrooms > 19*/}
                {/*                            ? styles.disabled_circle*/}
                {/*                            : ''*/}
                {/*                    }`}*/}
                {/*                    onClick={() => {*/}
                {/*                        if (*/}
                {/*                            formik.values.number_of_bathrooms <*/}
                {/*                            20*/}
                {/*                        ) {*/}
                {/*                            void formik.setFieldValue(*/}
                {/*                                'number_of_bathrooms',*/}
                {/*                                formik.values*/}
                {/*                                    .number_of_bathrooms + 1*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <AddIcon fontSize="medium" />*/}
                {/*                </Box>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*        <Box*/}
                {/*            className={styles.more_details_item}*/}
                {/*            sx={{ border: `0.08rem solid ${colors.lightBlue}` }}*/}
                {/*        >*/}
                {/*            <Box sx={{ color: colors.brown, display: 'block' }}>*/}
                {/*                <Typography fontWeight={600}>*/}
                {/*                    Packing Space*/}
                {/*                </Typography>*/}
                {/*                <Typography*/}
                {/*                    fontWeight={400}*/}
                {/*                    fontSize={'0.65rem'}*/}
                {/*                >*/}
                {/*                    (max no. of cars)*/}
                {/*                </Typography>*/}
                {/*            </Box>*/}
                {/*            <Box*/}
                {/*                display={'flex'}*/}
                {/*                alignItems={'center'}*/}
                {/*                gap={'1rem'}*/}
                {/*            >*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        border: `0.08rem solid ${colors.lightBlue}`,*/}
                {/*                    }}*/}
                {/*                    className={`${styles.circle} ${*/}
                {/*                        formik.values.number_of_packing_space <*/}
                {/*                        1*/}
                {/*                            ? styles.disabled_circle*/}
                {/*                            : ''*/}
                {/*                    }`}*/}
                {/*                    onClick={() => {*/}
                {/*                        if (*/}
                {/*                            formik.values*/}
                {/*                                .number_of_packing_space > 0*/}
                {/*                        ) {*/}
                {/*                            void formik.setFieldValue(*/}
                {/*                                'number_of_packing_space',*/}
                {/*                                formik.values*/}
                {/*                                    .number_of_packing_space - 1*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <RemoveIcon fontSize="medium" />*/}
                {/*                </Box>*/}
                {/*                <Typography*/}
                {/*                    sx={{ fontSize: '1.2rem', fontWeight: 600 }}*/}
                {/*                >*/}
                {/*                    {formik.values.number_of_packing_space}*/}
                {/*                </Typography>*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        border: `0.08rem solid ${colors.lightBlue}`,*/}
                {/*                    }}*/}
                {/*                    className={`${styles.circle} ${*/}
                {/*                        formik.values.number_of_packing_space >*/}
                {/*                        19*/}
                {/*                            ? styles.disabled_circle*/}
                {/*                            : ''*/}
                {/*                    }`}*/}
                {/*                    onClick={() => {*/}
                {/*                        if (*/}
                {/*                            formik.values*/}
                {/*                                .number_of_packing_space < 20*/}
                {/*                        ) {*/}
                {/*                            void formik.setFieldValue(*/}
                {/*                                'number_of_packing_space',*/}
                {/*                                formik.values*/}
                {/*                                    .number_of_packing_space + 1*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <AddIcon fontSize="medium" />*/}
                {/*                </Box>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid md={4} xs={12}>
                        {' '}
                        <Box>
                            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                                Property readiness status
                            </Text>
                            <Select
                                placeholder="Select Option"
                                name="property_information"
                                value={formik.values.property_information}
                                size="lg"
                                onChange={(_, value) => {
                                    void formik.setFieldValue(
                                        'property_information',
                                        value
                                    )
                                    void formik.setFieldTouched(
                                        'property_information',
                                        true
                                    )
                                }}
                            >
                                {Object.values(PropertyInformation)
                                    .filter(
                                        (pi) =>
                                            pi !==
                                                PropertyInformation.UNKNOWN &&
                                            pi !== PropertyInformation.OCCUPIED
                                    )
                                    .map((propertyInfo) => {
                                        return (
                                            <Option
                                                key={propertyInfo}
                                                value={propertyInfo}
                                            >
                                                {propertyInfo
                                                    .split('_')
                                                    .join(' ')}
                                            </Option>
                                        )
                                    })}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12} sm={12}>
                        <Box>
                            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                                What year was the property built?
                            </Text>
                            <Select
                                placeholder="Select Year"
                                // sx={{ maxWidth: '10rem' }}
                                name="year_built"
                                size="lg"
                                value={formik.values.year_built}
                                onChange={(_, value) => {
                                    void formik.setFieldValue(
                                        'year_built',
                                        value
                                    )
                                    void formik.setFieldTouched(
                                        'year_built',
                                        true
                                    )
                                }}
                            >
                                {years.map((year) => (
                                    <Option key={year} value={year}>
                                        {year}
                                    </Option>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12}>
                        <Box>
                            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                                Rental Duration (Advance)
                            </Text>
                            <Select
                                placeholder="Select advance"
                                name="rental_duration"
                                size="lg"
                                value={formik.values.rental_duration}
                                onChange={(_, value) => {
                                    void formik.setFieldValue(
                                        'rental_duration',
                                        value
                                    )
                                    void formik.setFieldTouched(
                                        'rental_duration',
                                        true
                                    )
                                }}
                            >
                                {RENTAL_DURATION.map((dur) => (
                                    <Option key={dur.value} value={dur.value}>
                                        {dur.title}
                                    </Option>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    {/*{formik.values.purpose_of_listing ===*/}
                    {/*    PurposeOfListingType.RENT && (*/}
                    {/*    <>*/}
                    <Grid md={4}>
                        <Text textFontSize={FontSize.Title}>Price</Text>
                        <TextInput
                            name="price"
                            type={'number'}
                            placeholder={'Price'}
                            size="lg"
                            showCurrency
                            value={formik.values.price}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errorText={
                                formik.touched.price && formik.errors?.price
                                    ? formik.errors.price
                                    : undefined
                            }
                        />
                    </Grid>
                    <Grid md={4}>
                        <Text textFontSize={FontSize.Title}>Rental Term</Text>
                        <Select
                            name="rental_term"
                            placeholder="Please select rental term"
                            size="lg"
                            sx={{
                                minWidth: '10rem',
                                maxWidth: '20rem',
                            }}
                            value={formik.values.rental_term}
                            onChange={(_, value) => {
                                void formik.setFieldValue('rental_term', value)
                                void formik.setFieldTouched('rental_term', true)
                            }}
                        >
                            {[
                                RentalTerm.MONTHLY,
                                //RentalTerm.YEARLY,
                                RentalTerm.DAILY,
                            ].map((dur) => (
                                <Option
                                    key={dur}
                                    value={dur}
                                    defaultValue={formik.values.rental_term}
                                >
                                    {dur}
                                </Option>
                            ))}
                        </Select>
                    </Grid>
                    {/*</>*/}
                    {/*)}*/}
                    <Grid md={4} xs={12}>
                        <Text textFontSize={FontSize.Title}>
                            Security deposit
                        </Text>
                        <TextInput
                            type={'number'}
                            placeholder={'Security deposit'}
                            size="lg"
                            showCurrency
                            name="security_deposit"
                            value={formik.values.security_deposit}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errorText={
                                formik.touched.security_deposit &&
                                formik.errors?.security_deposit
                                    ? formik.errors.security_deposit
                                    : undefined
                            }
                        />
                    </Grid>
                    {/*{formik.values.purpose_of_listing ===*/}
                    {/*    PurposeOfListingType.SHORT_STAY && (*/}
                    {/*    <Grid md={4} xs={12}>*/}
                    {/*        <Text textFontSize={FontSize.Title}>*/}
                    {/*            Price/night*/}
                    {/*        </Text>*/}
                    {/*        <TextInput*/}
                    {/*            type={'number'}*/}
                    {/*            placeholder={'Price/night'}*/}
                    {/*            size="lg"*/}
                    {/*            showCurrency*/}
                    {/*            currency="dollars"*/}
                    {/*            name="service_charge"*/}
                    {/*            value={formik.values.service_charge}*/}
                    {/*            onBlur={formik.handleBlur}*/}
                    {/*            onChange={formik.handleChange}*/}
                    {/*            errorText={*/}
                    {/*                formik.touched.service_charge &&*/}
                    {/*                formik.errors?.service_charge*/}
                    {/*                    ? formik.errors.service_charge*/}
                    {/*                    : undefined*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*)}*/}
                    {/*{formik.values.purpose_of_listing ===*/}
                    {/*    PurposeOfListingType.RENT && (*/}
                    {/*    <Grid md={4}>*/}
                    {/*        <Text textFontSize={FontSize.Title}>*/}
                    {/*            Service charge*/}
                    {/*        </Text>*/}
                    {/*        <TextInput*/}
                    {/*            type={'number'}*/}
                    {/*            placeholder={'Service charge'}*/}
                    {/*            size="lg"*/}
                    {/*            showCurrency*/}
                    {/*            currency="dollars"*/}
                    {/*            name="service_charge"*/}
                    {/*            value={formik.values.service_charge}*/}
                    {/*            onBlur={formik.handleBlur}*/}
                    {/*            onChange={formik.handleChange}*/}
                    {/*            errorText={*/}
                    {/*                formik.touched.service_charge &&*/}
                    {/*                formik.errors?.service_charge*/}
                    {/*                    ? formik.errors.service_charge*/}
                    {/*                    : undefined*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*)}*/}
                </Grid>
            </Box>

            <AmenitySelector
                label={'General Amenities'}
                value={formik.values.selected_general_amenities}
                options={(amenities.data || []).filter(
                    (amenity) => amenity.amenity_type === 'general amenities'
                )}
                onChange={(tags) =>
                    formik.setFieldValue('selected_general_amenities', tags)
                }
            />
            <AmenitySelector
                label={'Kitchen'}
                value={formik.values.selected_kitchen_amenities}
                options={(amenities.data || []).filter(
                    (amenity) => amenity.amenity_type === 'kitchen'
                )}
                onChange={(tags) =>
                    formik.setFieldValue('selected_kitchen_amenities', tags)
                }
            />
            <AmenitySelector
                placeholder={'Bedroom amenities'}
                label={'Bedroom'}
                options={(amenities.data || []).filter(
                    (amenity) => amenity.amenity_type === 'bedroom'
                )}
                value={formik.values.selected_bedroom_amenities}
                onChange={(tags) =>
                    formik.setFieldValue('selected_bedroom_amenities', tags)
                }
            />
            <AmenitySelector
                placeholder={'Bathroom amenities'}
                label={'Bathroom'}
                value={formik.values.selected_bathroom_amenities}
                options={(amenities.data || []).filter(
                    (amenity) => amenity.amenity_type === 'bathroom'
                )}
                onChange={(tags) =>
                    formik.setFieldValue('selected_bathroom_amenities', tags)
                }
            />
            <AmenitySelector
                placeholder={'Livingroom amenities'}
                label={'Livingroom'}
                value={formik.values.selected_livingroom_amenities}
                options={(amenities.data || []).filter(
                    (amenity) => amenity.amenity_type === 'livingroom'
                )}
                onChange={(tags) =>
                    formik.setFieldValue('selected_livingroom_amenities', tags)
                }
            />
            <AmenitySelector
                placeholder={'Outdoor amenities'}
                label={'Outdoor'}
                value={formik.values.selected_outdoor_amenities}
                options={(amenities.data || []).filter(
                    (amenity) => amenity.amenity_type === 'outdoor'
                )}
                onChange={(tags) =>
                    formik.setFieldValue('selected_outdoor_amenities', tags)
                }
            />
            <AmenitySelector
                placeholder={'Facility amenities'}
                label={'Facility'}
                value={formik.values.selected_facility_amenities}
                options={(amenities.data || []).filter(
                    (amenity) => amenity.amenity_type === 'facility'
                )}
                onChange={(tags) =>
                    formik.setFieldValue('selected_facility_amenities', tags)
                }
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: '2rem',
                }}
            >
                <Button
                    disabled={!formik.dirty || !formik.isValid}
                    sx={{ width: 150 }}
                    onClick={formik.submitForm}
                >
                    Next
                </Button>
            </Box>
        </React.Fragment>
    )
}
