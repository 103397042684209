import React from 'react'
import { Box, Container, Typography } from '@mui/joy'
import SvgIcons from '@/components/svg-icon'
import { FontSize } from '@/components/text/types'
import { Navbar } from '@/src/components/navbar'
import { colors } from '@/utilities/colors'

export function ErrorPage() {
    return (
        <React.Fragment>
            <Navbar />
            <Container
                sx={{
                    mt: 4,
                    minHeight: 'calc(100vh - 10rem)',
                    display: 'grid',
                    alignContent: 'center',
                    justifyContent: 'center',
                    gap: 5,
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Typography
                    level="h3"
                    sx={{
                        color: colors.brown,
                    }}
                >
                    Oops! Page Not Found
                </Typography>
                <Box>
                    <SvgIcons
                        iconName="page_not_found"
                        width={400}
                        height={200}
                    />
                </Box>
                <Typography
                    sx={{ fontSize: FontSize.Title, color: colors.brown }}
                >
                    We can't seem to find the page you're looking for. It might
                    have been moved or doesn't exist
                </Typography>
            </Container>
        </React.Fragment>
    )
}
