import React from 'react'
import { colors } from '@/utilities/colors'
import { Box, Input, InputProps, Typography } from '@mui/joy'
import { SxProps } from '@mui/system'
import { Currency, currencyList } from '@/utilities/currency'

interface TTextInputProps extends InputProps {
    showCurrency?: boolean
    currency?: Currency
    errorText?: string
    label?: string
    sxLabel?: SxProps
}

export const TextInput = React.forwardRef<HTMLInputElement, TTextInputProps>(
    (
        {
            label,
            onChange,
            showCurrency,
            currency = 'cedis',
            errorText,
            sxLabel,
            ...props
        },
        ref
    ) => {
        const isError = (errorText || '').trim().length > 0

        return (
            <Box>
                {label && (
                    <Box>
                        <Typography level={'body-sm'} sx={sxLabel}>
                            {label}
                        </Typography>
                    </Box>
                )}
                <Input
                    ref={ref}
                    {...props}
                    startDecorator={
                        showCurrency && (
                            <Typography>
                                <span>{currencyList[currency]}</span>
                            </Typography>
                        )
                    }
                    onChange={onChange}
                    sx={{
                        ...props.sx,
                        border: isError ? `1px solid ${colors.red}` : '',
                    }}
                />
                <Typography level={'body-xs'} sx={{ color: colors.red, mb: 1 }}>
                    {errorText}
                </Typography>
            </Box>
        )
    }
)
