// import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'

export type TQueryParam = {
    propertylocation?: string
    minPrice?: string
    maxPrice?: string
    noOfBeds?: string
    noOfBaths?: string
    propertytype?: string
    isRegion?: boolean
    checkIn?: string
    checkOut?: string
    noOfNights?: string
    noOfQuests?: string
}

export function useSearchFilter() {
    function buildQueryString(queryParam: TQueryParam) {
        const queries = []

        if (queryParam.propertylocation) {
            queries.push(
                `${queryParam.isRegion ? 'region' : 'neighbourhood'}=${queryParam.propertylocation}`
            )
        }

        if (queryParam.noOfBeds && Number(queryParam.noOfBeds) > 0) {
            queries.push(`max_number_of_beds=${queryParam.noOfBeds}`)
        }

        if (queryParam.noOfBaths && Number(queryParam.noOfBaths) > 0) {
            queries.push(`max_number_of_baths=${queryParam.noOfBaths}`)
        }

        if (queryParam.maxPrice && Number(queryParam.maxPrice) > 0) {
            queries.push(`max_price=${queryParam.maxPrice}`)
        }

        if (queryParam.minPrice && Number(queryParam.minPrice) > 0) {
            queries.push(`min_price=${queryParam.minPrice}`)
        }

        // queryParam.propertytype != PropertyType.UNKNOWN
        if (queryParam.propertytype) {
            queries.push(`property_types=${queryParam.propertytype}`)
        }

        if (queryParam.checkIn) {
            queries.push(`check_in_date=${queryParam.checkIn}`)
        }

        if (queryParam.checkOut) {
            queries.push(`check_out_date=${queryParam.checkOut}`)
        }

        if (queryParam.noOfNights) {
            queries.push(`number_of_nights=${queryParam.noOfNights}`)
        }

        if (queryParam.noOfQuests) {
            queries.push(`number_of_quests=${queryParam.noOfQuests}`)
        }

        return queries.length > 0 ? `?${queries.join('&')}` : ''
    }
    return { buildQueryString }
}
