import Analytics from 'analytics'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
    app: 'openprops',
    plugins: [
        googleAnalytics({
            measurementIds: [import.meta.env.VITE_FIREBASE_MEASUREMENTID],
        }),
    ],
})

export default analytics
