import React from 'react'
import {
    Box,
    CircularProgress,
    IconButton,
    Input,
    List,
    Sheet,
    Stack,
    Typography,
} from '@mui/joy'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ChatListItem } from '@/src/pages/secure/messaging/components/chat-list-item'
import { toggleMessagesPane } from '@/utilities/message'
import styles from '@/src/pages/secure/messaging/style.module.css'
import { useUserChatController } from '@/services/use-user-messaging'
import { UserChatList } from '@/services/use-user-messaging/provider/types'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
export function ChatsPane() {
    const authController = useGetAuthenticatedUser()
    const { getUserList, getMessages, isLoadingUsers, selectedConversation } =
        useUserChatController()

    const [originalList, setOriginalList] = React.useState<UserChatList[]>([])
    const [filteredList, setFilteredList] = React.useState<UserChatList[]>([])

    function handleSearchUsers(text: string) {
        let filteredUsers
        if (text === '') {
            filteredUsers = originalList
        } else {
            filteredUsers = originalList.filter(
                (user) =>
                    user.user_name.toLowerCase().includes(text.toLowerCase()) ||
                    user.landlord_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
            )
        }

        setFilteredList(filteredUsers)
    }

    React.useEffect(() => {
        const unsubscribe = getUserList((userList: UserChatList[]) => {
            setOriginalList(userList)
            setFilteredList(userList)
        })

        return () => {
            unsubscribe
        }
    }, [])

    return (
        <Sheet
            sx={{
                borderRight: '1px solid',
                borderColor: 'divider',
                height: 'calc(100dvh - var(--Header-height))',
                overflowY: 'auto',
            }}
            className={styles.scrollbar}
        >
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                p={2}
                pb={1.5}
            >
                <Typography
                    fontSize={{ xs: 'md', md: 'lg' }}
                    component="h1"
                    fontWeight="lg"
                    sx={{ mr: 'auto' }}
                >
                    Messages
                </Typography>
                <IconButton
                    variant="plain"
                    aria-label="edit"
                    color="neutral"
                    size="sm"
                    onClick={() => {
                        toggleMessagesPane()
                    }}
                    sx={{
                        display: {
                            xs: selectedConversation ? 'block' : 'none',
                            sm: 'none',
                        },
                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </Stack>
            <Box sx={{ px: 2, pb: 1.5 }}>
                <Input
                    size="sm"
                    name="searchText"
                    startDecorator={<SearchRoundedIcon />}
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e) => handleSearchUsers(e.target.value)}
                />
            </Box>
            {isLoadingUsers ? (
                <Box
                    sx={{
                        height: '100px',
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <List
                    sx={{
                        py: 0,
                        '--ListItem-paddingY': '0.75rem',
                        '--ListItem-paddingX': '1rem',
                    }}
                >
                    {filteredList.map((user, index) => (
                        <ChatListItem
                            key={index}
                            user={user}
                            /* selected={
                                user.conversationId ===
                                selectedConversation?.conversationId
                            }*/
                            onConversationSelect={() =>
                                getMessages(
                                    user.threadId,
                                    user.token,
                                    true,
                                    user.user_id === authController.user?.id
                                        ? user.landlord_name
                                        : user.user_name
                                )
                            }
                        />
                    ))}
                </List>
            )}
        </Sheet>
    )
}
