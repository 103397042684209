import { CssVarsProvider } from '@mui/joy/styles'
// import CssBaseline from '@mui/joy/CssBaseline'
import Box from '@mui/joy/Box'
import Header from '@/src/pages/secure/messaging/components/header'
import MyMessages from '@/src/pages/secure/messaging/components/my-messages'
import React from 'react'

export function ChatMessaging() {
    React.useEffect(() => {
        return () => {
            document.body.style.removeProperty('overflow')
        }
    }, [])
    return (
        <CssVarsProvider disableTransitionOnChange>
            <Box
                sx={{
                    display: 'flex',
                    height: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                }}
            >
                <Header />
                <Box component="main">
                    <MyMessages />
                </Box>
            </Box>
            {/*<CssBaseline />*/}
        </CssVarsProvider>
    )
}
