import React from 'react'
import { colors } from '@/utilities/colors'
import { Box, Button, Typography } from '@mui/joy'
import SvgIcons from '@/components/svg-icon'
import { PayWithPayStack } from '@/components/pay-with-paystack'
import { queryClient } from '@/utilities/react-query-client'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { Link } from 'react-router-dom'
import { useToastController } from '@/services/use-toast'

export function ShowVirtualTourCard() {
    const { isAuth } = useGetAuthenticatedUser()
    const toast = useToastController()

    function onSuccess() {
        toast.onOpen(
            'Your payment is being processed. Please wait a moment while we activate your pro subscription.',
            'success'
        )
        return void queryClient.invalidateQueries()
    }

    return (
        <React.Fragment>
            <SvgIcons
                iconName={isAuth ? 'lock' : 'tour_house'}
                width={100}
                height={100}
            />
            <Box
                display={'flex'}
                gap={2}
                mb={4}
                alignItems={'center'}
                flexDirection={'column'}
                width={'100%'}
            >
                <Typography
                    fontWeight={700}
                    sx={{
                        fontSize: { xs: '1.2rem', md: '2rem' },
                        color: colors.footerTextColor,
                    }}
                >
                    {isAuth
                        ? 'Get Unlimited Virtual Tours'
                        : 'Get Free Virtual Tour'}
                </Typography>
                <Typography textAlign={'center'} sx={{ textWrap: 'wrap' }}>
                    {isAuth
                        ? "You've exhausted your 2 free virtual tours! Upgrade to our Pro plan for unlimited access to virtual tours"
                        : 'Sign up now to access up to 2 free virtual tours! Explore apartments in detail and find the perfect fit for you. Get started and enjoy an immersive viewing experience.'}
                </Typography>

                {isAuth ? (
                    <PayWithPayStack
                        amount={25}
                        label="Subscribe to Pro plan"
                        onSuccess={onSuccess}
                    />
                ) : (
                    <Button
                        sx={{
                            outline: 0,
                            '&:focus': {
                                outline: 0,
                            },
                            '&:hover': {
                                color: colors.white,
                            },
                        }}
                        component={Link}
                        to="/auth/sign-up"
                    >
                        Sign Up to View Tour
                    </Button>
                )}
            </Box>
        </React.Fragment>
    )
}
