import {
    PropertySpaceType,
    PropertyType,
    // PurposeOfListingType,
    ResidentialType,
} from '@/src/pages/home/components/search-filter/types'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import {
    PropertyInformation,
    RentalTerm,
} from '@/services/use-get-properties/types.ts'

const residentialType = z
    .nativeEnum(ResidentialType, {
        errorMap: (issue) => {
            const messageMap: Record<string, string> = {
                invalid_type: 'Select the property residential type',
                invalid_enum_value:
                    "You've selected an invalid residential type",
                default: 'Select the property residential type',
            }

            return { message: messageMap[issue.code] || messageMap['default'] }
        },
    })
    .refine((val) =>
        [
            ResidentialType.House,
            ResidentialType.Apartment,
            ResidentialType.Townhouse,
        ].includes(val)
    )
const spaceTypeEnum = z.nativeEnum(PropertySpaceType, {
    errorMap: (issue) => {
        const messageMap: Record<string, string> = {
            invalid_type: 'Invalid space type',
            invalid_enum_value: "You've selected an invalid space type",
            default: 'Select the property space type',
        }

        return { message: messageMap[issue.code] || messageMap.default }
    },
})
const rentalTerm = z.nativeEnum(RentalTerm, {
    errorMap: (issue) => {
        const issueCodeToMessageMap: Record<string, string> = {
            invalid_type: 'Invalid Duration',
            invalid_enum_value: "You've selected an invalid Duration",
            default: 'Select the property Duration',
        }

        return {
            message:
                issueCodeToMessageMap[issue.code] ||
                issueCodeToMessageMap['default'],
        }
    },
})

// const purposeOfListingEnum = z.nativeEnum(PurposeOfListingType, {
//     errorMap: (issue) => {
//         const issueCodeToMessageMap: Record<string, string> = {
//             invalid_type: 'Invalid Purpose of Listing',
//             invalid_enum_value: "You've selected an invalid purpose of listing",
//             default: 'Select the purpose of listing',
//         }
//
//         return {
//             message:
//                 issueCodeToMessageMap[issue.code] ||
//                 issueCodeToMessageMap['default'],
//         }
//     },
// })

const schema = z.object({
    property_types: z
        .nativeEnum(PropertyType)
        .refine((val) => val !== PropertyType.UNKNOWN),
    overview: z.string().min(50, 'Description must be at least 50 characters'),
    neighbourhood: z.object({
        id: z.string().min(1),
        neighbourhood_name: z
            .string({
                required_error: 'Neighbourhood is required',
                invalid_type_error: 'Please select neighbourhood',
            })
            .min(1),
    }),
    region: z.object({
        id: z.string().min(1),
        region_name: z
            .string({
                required_error: 'Region is required',
                invalid_type_error: 'Please select region',
            })
            .min(1),
    }),
    address1: z
        .string({
            required_error: 'Address 1 is required',
        })
        .min(10, 'Address 1 must be at least 10 characters'),
    address2: z.string().optional(),
    property_space: spaceTypeEnum,
    residential_type: residentialType.optional(),
    rental_term: rentalTerm,
    price: z
        .number({
            invalid_type_error: 'Price is required',
            required_error: 'Price is required',
        })
        .positive('Price must be greater than zero'),
    security_deposit: z.number().refine((value) => value >= 0, {
        message: 'Must not be less than 0',
    }),
    service_charge: z.number().refine((value) => value >= 0, {
        message: 'Must not be less than 0',
    }),
    property_information: z
        .nativeEnum(PropertyInformation)
        .refine((value) => value !== PropertyInformation.UNKNOWN),
    // purpose_of_listing: purposeOfListingEnum,
    // price_per_night: z
    //     .number({
    //         invalid_type_error: 'Price per night is required',
    //         required_error: 'Price is required',
    //     })
    //     .positive('Price per night must be greater than zero'),
    // min_number_of_days: z
    //     .number({
    //         invalid_type_error: 'Minimum number of days is required',
    //         required_error: 'Minimum number of days is required',
    //     })
    //     .positive('Minimum number of days must be greater than zero'),
    // max_number_of_days: z
    //     .number({
    //         invalid_type_error: 'Maximum number of days is required',
    //         required_error: 'Maximum number of days is required',
    //     })
    //     .positive('Maximum number of days must be greater than zero'),
    // min_guest_allowed: z
    //     .number({
    //         invalid_type_error: 'Minimum guest allowed is required',
    //         required_error: 'Minimum guest allowed is required',
    //     })
    //     .positive('Minimum guest allowed must be greater than zero'),
    // max_guest_allowed: z
    //     .number({
    //         invalid_type_error: 'Maximum guest allowed is required',
    //         required_error: 'Maximum guest allowed is required',
    //     })
    //     .positive('Maximum guest allowed must be greater than zero'),
    // min_number_of_nights: z
    //     .number({
    //         invalid_type_error: 'Minimum number of nights is required',
    //         required_error: 'Minimum number of nights is required',
    //     })
    //     .positive('Minimum number of nights must be greater than zero'),
    // max_number_of_nights: z
    //     .number({
    //         invalid_type_error: 'Maximum number of nights is required',
    //         required_error: 'Maximum number of nights is required',
    //     })
    //     .positive('Maximum number of nights must be greater than zero'),
})

export const step1Schema = toFormikValidationSchema(schema)
