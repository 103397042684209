import React, { PropsWithChildren } from 'react'
import { useStytch } from '@stytch/react'
import { useNavigate } from 'react-router-dom'
import { QueryCache, useMutation } from '@tanstack/react-query'
import { Axios } from '@/utilities/axios.ts'
import { useToastController } from '@/services/use-toast'
import { StytchAPIError } from '@stytch/vanilla-js'

const SESSION_DURATION_IN_MINS = 10800
const queryCache = new QueryCache()

function useUserOnboarding() {
    const [userEmail, setUserEmail] = React.useState<string>()
    const [isLoading] = React.useState<boolean>(false)
    const stytchClient = useStytch()
    const navigate = useNavigate()
    const toast = useToastController()

    const logout = React.useCallback(() => {
        void stytchClient.session.revoke()
        queryCache.clear()
    }, [stytchClient])

    const onUserLogin = React.useCallback(
        async (user: { email: string; password: string }) => {
            try {
                await stytchClient.passwords.authenticate({
                    email: user.email,
                    password: user.password,
                    session_duration_minutes: SESSION_DURATION_IN_MINS,
                })
                navigate('/secure')
            } catch (e) {
                toast.onOpen('Login failed, Please check credentials', 'error')
                // let message = ''
                // if (
                //     // @ts-ignore
                //     'name' in e &&
                //     e.name === 'StytchSDKAPIError' &&
                //     'error_message' in e
                // ) {
                //     // message = e.error_message as string
                //     return
                // }
            }
        },
        [navigate, stytchClient, toast]
    )

    const registerMutation = useMutation({
        mutationFn: async (data: {
            email: string
            first_name: string
            last_name: string
        }) => {
            return Axios.post('/user', data)
        },
    })

    const loginOrRegisterMutation = useMutation({
        mutationFn: async (data: {
            email: string
            first_name: string
            last_name: string
        }) => {
            return Axios.post('/user/loginorregister', data)
        },
    })

    const onUserRegistration = React.useCallback(
        async (user: {
            email: string
            password: string
            firstName: string
            lastName: string
        }) => {
            try {
                setUserEmail(user.email)
                await stytchClient.passwords.create({
                    email: user.email,
                    password: user.password,
                    session_duration_minutes: SESSION_DURATION_IN_MINS,
                })
                await stytchClient.user.update({
                    name: {
                        first_name: user.firstName,
                        last_name: user.lastName,
                    },
                })

                await registerMutation.mutateAsync({
                    email: user.email,
                    first_name: user.firstName,
                    last_name: user.lastName,
                })
                toast.onOpen('User registration successful!', 'success')
                navigate('/auth/getting-started')
            } catch (e) {
                console.log('Sign up error', e)
                if (e instanceof StytchAPIError) {
                    if (e.error_type === 'duplicate_email') {
                        toast.onOpen('User with email already exists', 'error')
                        navigate('/auth/login')
                        return
                    }
                }
                toast.onOpen('Unable to register user', 'error')
            }
        },
        [stytchClient, registerMutation, navigate, toast]
    )

    const checkPasswordStrength = React.useCallback(
        (userPassword: string) => {
            return stytchClient.passwords.strengthCheck({
                password: userPassword,
            })
        },
        [stytchClient]
    )

    async function onGoogleLogin() {
        try {
            await stytchClient.oauth.google.start({
                signup_redirect_url: `${import.meta.env.VITE_OAUTH_URL}`,
                login_redirect_url: `${import.meta.env.VITE_OAUTH_URL}`,
                custom_scopes: [],
                provider_params: {
                    login_hint: 'example_hint@stytch.com',
                },
            })
        } catch (e) {
            console.log(e)
        }
    }

    return {
        userEmail,
        isLoading,
        onGoogleLogin,
        onLogin: onUserLogin,
        onRegister: onUserRegistration,
        onLoginOrRegister: loginOrRegisterMutation,
        onLogout: logout,
        checkPasswordStrength,
    }
}

type UserOnboardingContext = ReturnType<typeof useUserOnboarding>
export const Context = React.createContext<UserOnboardingContext | undefined>(
    undefined
)

export function UserOnboardingProvider(props: PropsWithChildren) {
    const value = useUserOnboarding()
    return <Context.Provider value={value}>{props.children}</Context.Provider>
}
