import { Property } from '@/services/use-get-properties/types.ts'
import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'

export type TPropertyLocationAutoCompleteOption = {
    name: string
    isRegion: boolean
    id: string
}

export function isCommercial(property?: Property) {
    return property?.property_types === PropertyType.COMMERCIAL
}

export function isResidential(property?: Property) {
    return property?.property_types === PropertyType.RESIDENTIAL
}

export function makePrural(word: string, value: number, pruralSuffix: string) {
    if (value > 1) {
        return word + pruralSuffix
    }

    return word
}

export function replaceAlphabetsWithEmptyString(word: string) {
    return word.replace(/\D/g, '')
}

export const RENTAL_DURATION = [
    {
        value: 3,
        title: '3 months',
    },
    {
        value: 6,
        title: '6 months',
    },

    {
        value: 12,
        title: '1 Year',
    },

    {
        value: 18,
        title: '1.5 years',
    },

    {
        value: 24,
        title: '2 years',
    },

    {
        value: 36,
        title: '3 years',
    },

    {
        value: 48,
        title: '4 years',
    },
]
