import React, { PropsWithChildren } from 'react'
import Snackbar from '@mui/joy/Snackbar'

type ToastVariant = 'success' | 'error'

function useToast() {
    const [toastVariant, setToastVariant] =
        React.useState<ToastVariant>('success')
    const [message, setMessage] = React.useState<string>('')
    const [open, setOpen] = React.useState<boolean>(false)

    function onOpen(message: string, variant: ToastVariant) {
        setMessage(message)
        setOpen(true)
        setToastVariant(variant)
        setTimeout(() => {
            onClose()
        }, 4000)
    }

    function onClose() {
        setMessage('')
        setOpen(false)
        setToastVariant('success')
    }

    return {
        onClose,
        onOpen,
        message,
        open,
        toastVariant,
    }
}

type ToastContext = ReturnType<typeof useToast>
const Context = React.createContext<ToastContext | undefined>(undefined)

export function useToastController() {
    const context = React.useContext(Context)
    if (!context) {
        throw new Error(
            'You must wrapper with AuthUserProvider to use this hook'
        )
    }
    return context
}

type TToastProvider = PropsWithChildren<{
    value?: ToastContext
}>

export function ToastProvider(props: TToastProvider) {
    const value = useToast()
    return (
        <Context.Provider value={props.value || value}>
            {value.message && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={value.open}
                    onClose={value.onClose}
                    variant={'soft'}
                    color={
                        value.toastVariant === 'success' ? 'success' : 'danger'
                    }
                >
                    {value.message}
                </Snackbar>
            )}
            {props.children}
        </Context.Provider>
    )
}
