import React from 'react'
import { Box } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { Text } from '@/components/text'
import { Button, Typography, Grid } from '@mui/joy'
import { useGetLandlordProperties } from '@/services/use-get-properties'
import { Property } from '@/services/use-get-properties/types.ts'
import { colors } from '@/utilities/colors'
import AddIcon from '@mui/icons-material/Add'
import NoPropertyIcon from '@/src/pages/secure/properties/view-property/assets/svgs/no-property-icon.svg?react'
import { SecureHeader } from '@/src/pages/secure/components/secure-header'
import { VerifyIdentityModal } from './components/verify-identity-modal'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { PropertyCard } from '@/components/property-card'
import { useQueryParams } from '@/utilities/use-query-params'
import { Pagination } from '@/components/pagination'

export function ViewProperty() {
    const navigate = useNavigate()
    const [showModal, setShowModal] = React.useState(false)
    const { user } = useGetAuthenticatedUser()
    const [queryString, setQueryString] = React.useState<
        { limit?: string | null; page?: string | null } | undefined
    >()
    const properties = useGetLandlordProperties(queryString)

    const queryParams = useQueryParams()
    const page = queryParams.get('page') || '1'

    function onClickCreateProperty() {
        if (!user?.is_identity_card_valid) {
            setShowModal(true)
            return
        }
        navigate(`/secure/properties/create`)
    }

    function buildQueryObject() {
        const queryObj: Record<string, string> = {}
        for (const [key, value] of queryParams.entries()) {
            queryObj[key] = value
        }
        return queryObj
    }
    React.useEffect(() => {
        if (queryParams.size === 0) {
            return
        }
        const queryObj = buildQueryObject()
        console.log(queryObj)
        //get all values from queryParams
        setQueryString(queryObj)
    }, [queryParams])

    function onFetchNextPage(page?: number) {
        if (page) {
            queryParams.set('page', String(page))
            // setQueryString(queryParams)
            navigate(`?${queryParams.toString()}`)
        }
    }

    function onFetchPrevPage(page?: number) {
        if (page) {
            queryParams.set('page', String(page))
            navigate(`?${queryParams.toString()}`)
        }
    }

    return (
        <React.Fragment>
            <SecureHeader />
            <Box padding={3}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Box
                        sx={{
                            borderRadius: '2rem',
                            padding: '0.4rem 1rem',
                            width: 'fit-content',
                            boxShadow: '0px 0px 6px 0px rgba(157,154,154,0.75)',
                            fontSize: { xs: 14, sm: 16, md: 18 },
                        }}
                    >
                        {properties.data?.totalProperties} properties listed
                    </Box>
                    <Box>
                        <Button
                            onClick={onClickCreateProperty}
                            sx={{
                                px: { xs: 1.2, sm: 3, md: 3.4 },
                                ml: 0.5,
                                display: 'flex',
                                gap: 1,
                            }}
                        >
                            <AddIcon />
                            <Text
                                sx={{
                                    fontSize: {
                                        xs: 15,
                                        sm: 17,
                                        md: 19,
                                    },
                                    fontWeight: 'bold',
                                    color: colors.white,
                                }}
                            >
                                Create Listing
                            </Text>
                        </Button>
                    </Box>
                </Box>
                {(properties.data?.properties || []).length === 0 && (
                    <Box
                        width={'100%'}
                        mt={6}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                    >
                        <NoPropertyIcon />
                        <Box textAlign={'center'}>
                            <Typography mb={2} level={'h2'}>
                                No Properties Listed
                            </Typography>
                            <Typography>
                                Click the button on the top right corner of your
                                screen to list a property
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Grid
                    container
                    spacing={4}
                    mt={3}
                    sx={{ alignItems: 'stretch' }}
                >
                    {properties.isLoading ||
                        (properties.isFetching && 'Loading...')}
                    {(properties.data?.properties || []).map(
                        (property: Property) => (
                            <Grid
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                mb={3}
                                key={property.id}
                            >
                                <PropertyCard
                                    isFaveDisabled
                                    hasEditAndDeleteActions
                                    property={property}
                                />
                            </Grid>
                        )
                    )}
                </Grid>
                {(properties.data?.totalPages || 0) > 0 && (
                    <Pagination
                        currentPage={Number(page) || 1}
                        totalPages={properties.data?.totalPages || 0}
                        hasNextPage={Boolean(
                            (properties.data?.currentPage || 1) <
                                (properties.data?.totalPages || 1)
                        )}
                        hasPreviousPage={Boolean(
                            (properties.data?.currentPage || 1) >=
                                (properties.data?.totalPages || 1)
                        )}
                        fetchNextPage={onFetchNextPage}
                        fetchPreviousPage={onFetchPrevPage}
                    />
                )}
            </Box>
            <VerifyIdentityModal
                isPendingVerification={Boolean(user?.has_uploaded_id_card)}
                isOpen={showModal}
                onToggle={() => setShowModal(false)}
            />
        </React.Fragment>
    )
}
