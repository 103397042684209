import {
    Box,
    FormHelperText,
    List,
    ListItem,
    Radio,
    RadioGroup,
    useTheme,
} from '@mui/joy'
import { useMediaQuery } from '@mui/material'
import { colors } from '@/utilities/colors'
import { FontSize } from '@/components/text/types.ts'
import { Text } from '@/components/text'

type TOptions = {
    name: string
    value: string
}

interface IPropertySpace {
    property_space: string
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    touched: boolean
    error: string
    options: TOptions[]
}
export function PropertySpace(props: IPropertySpace) {
    const theme = useTheme()
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Box>
            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                Please choose one option
            </Text>
            <RadioGroup
                aria-label="Property space"
                orientation="horizontal"
                name="property_space"
                value={props.property_space}
                onChange={props.handleChange}
            >
                <List
                    orientation={isMobileDevice ? 'vertical' : 'horizontal'}
                    sx={{
                        minWidth: 240,
                        '--List-gap': '0.5rem',
                        '--ListItem-paddingY': '1rem',
                        '--ListItem-radius': '8px',
                        '--ListItemDecorator-size': '32px',
                    }}
                >
                    {props.options.map((item) => (
                        <ListItem
                            variant="outlined"
                            key={item.value}
                            sx={{
                                boxShadow: 'sm',
                                backgroundColor: colors.white,
                            }}
                        >
                            <Radio
                                overlay
                                value={item.value}
                                label={item.name}
                                sx={{
                                    '&.Mui-checked': {
                                        color: colors.primary,
                                    },
                                    flexGrow: 1,
                                    flexDirection: 'row-reverse',
                                    textTransform: 'capitalize',
                                }}
                                slotProps={{
                                    action: ({ checked }) => ({
                                        sx: () => ({
                                            ...(checked && {
                                                inset: -1,
                                                border: '2px solid',
                                                borderColor: colors.primary,
                                            }),
                                        }),
                                    }),
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </RadioGroup>
            <FormHelperText sx={{ color: colors.red }}>
                {props.touched && props.error ? props.error : undefined}
            </FormHelperText>
        </Box>
    )
}
