import * as React from "react";
const SvgKettle = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M1.25,17.79L1.25,10.47C1.25,7.725 3.477,5.51 6.21,5.51L13.58,5.51C15.498,5.51 17.166,6.606 17.991,8.204C20.632,8.309 22.75,10.482 22.75,13.16C22.75,15.641 20.915,17.705 18.533,18.064C18.39,20.681 16.222,22.75 13.58,22.75L6.21,22.75C3.477,22.75 1.25,20.523 1.25,17.79ZM17.04,10.47C17.04,8.563 15.487,7.01 13.58,7.01L6.21,7.01C4.303,7.01 2.75,8.555 2.75,10.47L2.75,11.25L17.04,11.25L17.04,10.47ZM18.54,16.538C20.088,16.194 21.25,14.809 21.25,13.16C21.25,11.504 20.088,10.124 18.54,9.782C18.54,9.782 18.54,10.046 18.54,10.47C18.54,12.341 18.54,15.527 18.54,16.538ZM2.75,12.75L2.75,17.79C2.75,19.697 4.303,21.25 6.21,21.25L13.58,21.25C15.487,21.25 17.04,19.705 17.04,17.79L17.04,12.75L2.75,12.75ZM6.25,4C6.25,4.414 5.914,4.75 5.5,4.75C5.086,4.75 4.75,4.414 4.75,4L4.75,2.25C4.75,1.836 5.086,1.5 5.5,1.5C5.914,1.5 6.25,1.836 6.25,2.25L6.25,4ZM10.25,4C10.25,4.414 9.914,4.75 9.5,4.75C9.086,4.75 8.75,4.414 8.75,4L8.75,2.25C8.75,1.836 9.086,1.5 9.5,1.5C9.914,1.5 10.25,1.836 10.25,2.25L10.25,4ZM14.25,4C14.25,4.414 13.914,4.75 13.5,4.75C13.086,4.75 12.75,4.414 12.75,4L12.75,2.25C12.75,1.836 13.086,1.5 13.5,1.5C13.914,1.5 14.25,1.836 14.25,2.25L14.25,4Z", style: {
  fill: "rgb(94,76,90)"
} }));
export default SvgKettle;
