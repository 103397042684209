import { Link, NavLink } from 'react-router-dom'
import { DateTime } from 'luxon'
import {
    Box,
    Divider,
    Typography,
    List,
    ListItem,
    Grid,
    Container,
    ListItemDecorator,
} from '@mui/joy'
import CallIcon from '@mui/icons-material/Call'
// import EmailIcon from '@mui/icons-material/Email'
import InstagramIcon from '@/assets/svgs/instagram-icon.svg?react'
import TwitterIcon from '@/assets/svgs/x-icon.svg?react'
import LinkedInIcon from '@/assets/svgs/linkedin-icon.svg?react'

import { colors } from '@/utilities/colors.ts'

import styles from '@/components/footer/footer.module.css'

const currentYear = DateTime.now().year
export function Footer() {
    const links = [
        {
            href: '/properties?region=652390a94e8b1f63133f565e&property_types=residential&is_region=true',
            title: 'Apartment for rent in Accra',
        },
        {
            href: '/properties?region=652390a94e8b1f63133f565e&property_types=residential&is_region=true',
            title: 'Townhouse for rent in Accra',
        },
    ]

    return (
        <footer className={styles.wrapper}>
            <Box
                sx={{
                    bgcolor: colors.lightGreen,
                    pt: 3,
                    pb: 3,
                }}
            >
                <Container maxWidth={'lg'}>
                    <Grid container>
                        <Grid
                            md={3}
                            sm={12}
                            sx={{
                                order: {
                                    xs: 3,
                                    sm: 0,
                                },
                                mt: { xs: '1rem', sm: 0 },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: 15, sm: 17 },
                                    fontWeight: 700,
                                    color: colors.secondary,
                                }}
                                className={styles.footer_item_title}
                            >
                                OPENPROP SUPPORT
                            </Typography>
                            <Box className={styles.footer_item_list}>
                                <List>
                                    <ListItem sx={{ pl: 0 }}>
                                        <ListItemDecorator>
                                            <CallIcon
                                                width={30}
                                                sx={{ color: colors.brown }}
                                            />
                                        </ListItemDecorator>
                                        <Typography
                                            level={'body-sm'}
                                            sx={{ color: '#5E4C5A' }}
                                        >
                                            +233546919820
                                        </Typography>
                                    </ListItem>
                                    {/* <ListItem sx={{ pl: 0 }}>
                                        <ListItemDecorator>
                                            <EmailIcon
                                                sx={{ color: colors.brown }}
                                            />
                                        </ListItemDecorator>
                                        <Typography
                                            level={'body-sm'}
                                            sx={{ color: '#5E4C5A' }}
                                        >
                                            info@razorlabs.tech
                                        </Typography>
                                    </ListItem> */}
                                </List>
                            </Box>
                        </Grid>
                        <Grid
                            md={3}
                            sm={12}
                            xs={12}
                            sx={{
                                mt: { xs: '1rem', md: 0 },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 17,
                                    fontWeight: 700,
                                    color: colors.secondary,
                                }}
                                className={styles.footer_item_title}
                            >
                                POPULAR SEARCHES
                            </Typography>
                            <Box className={styles.footer_item_list}>
                                <List>
                                    {links.map((link, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                pl: 0,
                                                py: 0,
                                                fontWeight: 400,
                                            }}
                                            component={Link}
                                            to={`${link.href}`}
                                        >
                                            <Typography
                                                level="body-sm"
                                                sx={{ color: '#5E4C5A' }}
                                            >
                                                {link.title}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Grid>
                        <Grid
                            md={3}
                            sm={12}
                            xs={12}
                            sx={{
                                order: {
                                    sm: -1,
                                    xs: -1,
                                    md: 1,
                                    lg: 1,
                                },
                                mt: { xs: '1rem', md: 0 },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 17,
                                    fontWeight: 700,
                                    display: { sm: 'block' },
                                    color: colors.secondary,
                                    mb: { xs: 0, sm: 'initial' },
                                }}
                                className={styles.footer_item_title}
                            >
                                ABOUT US
                            </Typography>
                            <Box sx={{ mt: { xs: 0, sm: 0 } }}>
                                <List>
                                    <ListItem sx={{ pl: 0 }}>
                                        <Typography
                                            level={'body-sm'}
                                            sx={{ color: '#5E4C5A' }}
                                        >
                                            Our mission is to make rental
                                            process easy for tenants and
                                            landlords in Ghana.
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            pl: 0,
                                            mt: { xs: 0.5, sm: 0 },
                                        }}
                                    >
                                        <Typography
                                            level={'body-sm'}
                                            sx={{ color: '#5E4C5A' }}
                                        >
                                            Openprop allows you to manage your
                                            listing, verify your future
                                            landlords and prospective tenants.
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>

                        <Grid
                            md={3}
                            sm={12}
                            xs={12}
                            sx={{
                                order: {
                                    sm: 1,
                                    xs: 3,
                                    md: 1,
                                    lg: 1,
                                },
                                mt: { xs: '1.5rem', md: 0 },
                            }}
                        >
                            <Typography
                                level="body-xs"
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    fontSize: 17,
                                    fontWeight: 700,
                                    color: colors.secondary,
                                }}
                                className={styles['footer_item_title']}
                            >
                                SOCIALS
                            </Typography>
                            <Box className={styles.footer_item_list}>
                                <List
                                    orientation={'horizontal'}
                                    sx={{
                                        pl: 0,
                                        mt: { xs: '-1rem', sm: 0 },
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            pl: 0,
                                            ml: 0,
                                            pr: { sm: '0.5rem' },
                                        }}
                                    >
                                        <NavLink
                                            to={
                                                'https://www.instagram.com/razor.labs/'
                                            }
                                            target={'_blank'}
                                        >
                                            <ListItemDecorator
                                                sx={{ minWidth: 30 }}
                                            >
                                                <InstagramIcon
                                                    aria-label="instagram"
                                                    data-testid={
                                                        'instagram-icon'
                                                    }
                                                />
                                            </ListItemDecorator>
                                        </NavLink>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            px: { xs: '1.3rem', sm: '0.5rem' },
                                        }}
                                    >
                                        <NavLink
                                            to={'https://twitter.com/openprop_'}
                                            target={'_blank'}
                                        >
                                            <ListItemDecorator
                                                sx={{ minWidth: 30 }}
                                            >
                                                <TwitterIcon
                                                    aria-label="twitter"
                                                    data-testid={'twitter-icon'}
                                                />
                                            </ListItemDecorator>
                                        </NavLink>
                                    </ListItem>
                                    <ListItem>
                                        <NavLink
                                            to={
                                                'https://www.linkedin.com/company/razorlabs-tech'
                                            }
                                            target={'_blank'}
                                        >
                                            <ListItemDecorator
                                                sx={{ minWidth: 30 }}
                                            >
                                                <LinkedInIcon
                                                    aria-label="linkedin"
                                                    data-testid={
                                                        'linkedin-icon'
                                                    }
                                                />
                                            </ListItemDecorator>
                                        </NavLink>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box
                        className={styles.terms}
                        sx={{ width: '100%', mt: { xs: '0.5rem', sm: 0 } }}
                    >
                        <List
                            orientation={'horizontal'}
                            sx={{
                                display: 'flex',
                                mx: { xs: 0, sm: 0 },
                                placeItems: 'center',
                                gap: 4,
                                pl: 0,
                                justifyContent: {
                                    xs: 'flex-start',
                                    sm: 'center',
                                },
                            }}
                        >
                            <ListItem
                                sx={{
                                    px: 0,
                                    pl: { xs: 0, sm: 0 },
                                }}
                            >
                                <Link to={'/terms-of-service'}>
                                    <Typography
                                        level={'body-sm'}
                                        sx={{ color: '#467565' }}
                                    >
                                        Terms of Service
                                    </Typography>
                                </Link>
                            </ListItem>
                            <ListItem
                                sx={{ px: 0, ml: { xs: '-0.5rem', sm: 0 } }}
                            >
                                <Link to={'/privacy-policy'}>
                                    <Typography
                                        level={'body-sm'}
                                        sx={{ color: '#467565' }}
                                    >
                                        Privacy Policy
                                    </Typography>
                                </Link>
                            </ListItem>
                        </List>
                    </Box>
                </Container>
                <Divider />
                <Box
                    sx={{
                        marginBottom: '10px',
                        ml: { xs: -2, sm: 0 },
                        mt: { xs: 2, sm: 0 },
                    }}
                />
                <Typography
                    level="body-xs"
                    sx={{
                        // width: '100',
                        textAlign: { xs: 'left', sm: 'center' },
                        pl: { xs: 1, sm: 0 },
                        color: '#5E4C5A',
                        mt: { xs: 2, sm: 1 },
                    }}
                >
                    &copy; RAZORLABS, {currentYear}
                </Typography>
            </Box>
        </footer>
    )
}
