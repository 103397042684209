import { PropertyInformation } from '@/services/use-get-properties/types.ts'

export function handlePropertyInformation(
    propertyInformation: PropertyInformation[],
    isAgent?: boolean
) {
    if (
        isAgent &&
        propertyInformation.includes(PropertyInformation.NEEDS_WORK)
    ) {
        return 'This property is managed by external agent and needs some work.'
    }

    if (isAgent) {
        return 'This listing is managed by external agent.'
    }

    if (propertyInformation.includes(PropertyInformation.NEEDS_WORK)) {
        return 'Property needs work'
    }

    if (propertyInformation.includes(PropertyInformation.UNFINISHED)) {
        return 'Some work needed'
    }

    if (propertyInformation.includes(PropertyInformation.MOVE_IN_READY)) {
        return 'Move In Ready'
    }
    if (propertyInformation.includes(PropertyInformation.OCCUPIED)) {
        return 'Property Occupied'
    }
}
