import { colors } from '@/utilities/colors'
import { Badge, Box, Typography } from '@mui/joy'
import SvgIcons from '@/components/svg-icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserChatController } from '@/services/use-user-messaging'

export function NavigationTab() {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { unreadMessagesCount } = useUserChatController()
    const menuItems = [
        {
            text: 'Home',
            icon: <SvgIcons iconName="home_inactive" />,
            iconActive: <SvgIcons iconName="home_active" />,
            link: '/',
        },
        {
            text: 'Property',
            icon: <SvgIcons iconName="property" />,
            iconActive: <SvgIcons iconName="property_active" />,
            link: '/properties',
        },
        {
            text: 'Message',
            icon: (
                <Badge
                    badgeContent={unreadMessagesCount}
                    max={999}
                    size="sm"
                    color="primary"
                >
                    <SvgIcons iconName="message" />
                </Badge>
            ),
            iconActive: (
                <Badge badgeContent={unreadMessagesCount} color="primary">
                    <SvgIcons iconName="message_active" />
                </Badge>
            ),
            link: '/secure/messages',
        },
        {
            text: 'Profile',
            icon: <SvgIcons iconName="profile" />,
            iconActive: <SvgIcons iconName="profile_active" />,
            link: '/secure/settings',
        },
    ]

    return (
        <Box
            left={0}
            right={0}
            bgcolor={'white'}
            bottom={0}
            zIndex={1300}
            position={'fixed'}
            px={3}
            py={2}
            height={40}
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={'row'}
            borderTop={2}
            borderColor={colors.primary}
        >
            {menuItems.map((item) => {
                return (
                    <Box
                        key={item.text}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        onClick={() => navigate(`${item.link}`)}
                    >
                        {pathname === item.link ? item.iconActive : item.icon}
                        <Typography
                            sx={{
                                fontSize: 14,
                                color:
                                    pathname === item.link
                                        ? colors.primary
                                        : colors.gray1,
                            }}
                        >
                            {item.text}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}
