import { Container } from '@mui/joy'
import { Text } from '@/components/text'
import { FontSize } from '@/components/text/types'

export function VirtualTourSection() {
    return (
        <Container maxWidth="xl" sx={{ mt: '3rem' }}>
            <Text
                sx={{
                    my: 1,
                    fontSize: 22,
                    fontWeight: 600,
                    textAlign: 'center',
                }}
            >
                Tour your next home from Anywhere, Anytime
            </Text>
            <Text
                mb={2.5}
                sx={{
                    lineHeight: { xs: 1.6, md: 2 },
                    textAlign: 'center',
                    fontSize: FontSize.Title,
                }}
            >
                Save time, money, and stress on your apartment search with
                Openprop’s virtual tours—no viewing fees required!
            </Text>
            <iframe
                width="100%"
                height="500"
                src={
                    'https://kuula.co/share/collection/7Z7Jc?logo=1&info=1&logosize=122&fs=1&vr=0&sd=1&initload=0&thumbs=1'
                }
                allowFullScreen
                allow="xr-spatial-tracking"
            />
        </Container>
    )
}
