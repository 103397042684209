import SvgIcons from '@/components/svg-icon'
import { colors } from '@/utilities/colors'
import { Container, Typography } from '@mui/joy'

export function Subscribed() {
    return (
        <Container
            maxWidth={'md'}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginY: 10,
                gap: 1,
                textAlign: 'center',
            }}
        >
            <SvgIcons
                iconName="subscription_checked"
                width={105}
                height={105}
            />
            <Typography
                sx={{
                    color: colors.brown,
                    whiteSpace: 'wrap',
                    py: 2,
                    fontSize: 30,
                    fontWeight: 600,
                }}
            >
                Subscription successful!
            </Typography>
            <Typography sx={{ fontSize: 18, width: { xs: '100%', md: '80%' } }}>
                Thank you for upgrading! You now have full access to interior
                and exterior virtual tours, exclusive listings, and 24/7
                customer support
            </Typography>
        </Container>
    )
}
