import { useNavigate } from 'react-router-dom'
import { PaystackButton } from 'react-paystack'
import { Box, styled, Button } from '@mui/joy'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { colors } from '@/utilities/colors'

type TPayWithPayStackProps = {
    amount: number
    label: string
    onSuccess: () => void
}

const PayStackBtn = styled(PaystackButton)`
    background-color: ${colors.primary};
    width: 100%;
    color: white;
`
const Btn = styled(Button)`
    background-color: ${colors.primary};
    width: 100%;
    color: white;
    font-weight: 500;
    font-size: 1em;
`

export function PayWithPayStack(props: TPayWithPayStackProps) {
    const navigate = useNavigate()
    const authUserController = useGetAuthenticatedUser()
    const name =
        authUserController.user?.first_name +
        ' ' +
        authUserController.user?.last_name
    const phone = authUserController.user?.phone
    const customFields = [
        {
            display_name: 'Name',
            variable_name: 'name',
            value: name,
        },
        {
            display_name: 'Phone',
            variable_name: 'phone',
            value: phone,
        },
    ]
    const componentProps = {
        email: authUserController.user?.email || '',
        amount: props.amount * 100,
        currency: 'GHS',
        metadata: {
            name,
            phone,
            custom_fields: customFields,
        },
        publicKey: import.meta.env.VITE_PAYSTACK,
        text: props.label,
        onSuccess: props.onSuccess,
    }

    return (
        <Box>
            {authUserController.isAuth ? (
                <PayStackBtn {...componentProps} />
            ) : (
                <Btn onClick={() => navigate('/auth/login?redirect=pricing')}>
                    Subscribe
                </Btn>
            )}
        </Box>
    )
}
