import React from 'react'
import { Box, Button, Option, Select, Stack, Typography } from '@mui/joy'
import { FormikValues, useFormik } from 'formik'
// import emailjs from '@emailjs/browser'
import { TextInput } from '@/components/text-input'
import { waitlistSchema } from '@/components/waitlist/schema'
import { colors } from '@/utilities/colors'
import { Text } from '@/components/text'
import { db } from '@/utilities/firebase-config'
import { setDoc, doc, deleteDoc, DocumentReference } from 'firebase/firestore'

export function Waitlist() {
    // const public_key = import.meta.env.VITE_EMAILJS_PUBLIC_KEY

    const form = React.useRef<HTMLFormElement | null>(null)
    const [error, setError] = React.useState<boolean>(false)
    const [response, setResponse] = React.useState<string | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)

    const propertyTypes = [
        'single room self contain',
        'Chamber and Hall',
        '2 bedroom',
        '3 bedroom',
        'studio',
        'short stay',
        'other',
    ]

    const formik = useFormik({
        initialValues: {
            fullName: '',
            phoneNumber: '',
            preferredLocation: '',
            budget: '',
            propertyType: '',
        },
        onSubmit: async (values, { resetForm }) => {
            setResponse('')
            if (form.current) {
                setLoading(true)
                setError(false)

                const docRef = doc(db, 'waitlist', generateUniqueId())

                try {
                    await storeToFirebase(values, docRef)

                    // const emailResponse = await emailjs.sendForm(
                    //     'openprop',
                    //     'openprop',
                    //     form.current,
                    //     public_key
                    // )

                    // if (emailResponse.status === 200) {
                    setResponse('Form sent successfully!')
                    resetForm()
                    // } else {
                    //     await deleteDoc(docRef)
                    //     setError(true)
                    //     setResponse('Form submission failed.')
                    //     setLoading(false)
                    // }
                } catch (error) {
                    setError(true)
                    setResponse('Form submission failed.')
                    await deleteDoc(docRef)
                    setLoading(false)
                } finally {
                    setLoading(false)
                }
            }
        },

        validationSchema: waitlistSchema,
    })

    async function storeToFirebase(
        values: FormikValues,
        docRef: DocumentReference
    ) {
        await setDoc(docRef, {
            fullName: values.fullName,
            phoneNumber: values.phoneNumber,
            preferredLocation: values.preferredLocation,
            budget: values.budget,
            propertyType: values.propertyType,
            createdAt: new Date(),
        })
    }

    function generateUniqueId() {
        return Math.random().toString(36).slice(2, 14)
    }

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        formik.handleChange(e)
        setError(false)
        setResponse('')
    }
    return (
        <Box sx={{ mx: { xs: '0.5rem', md: '2rem' } }}>
            <Text sx={{ fontWeight: 400, fontSize: 18 }}>
                No listings are available for your search.
            </Text>
            <Text sx={{ fontWeight: 300, fontSize: 18 }} mt={1}>
                Fill out the form below with your details, and will be notified
                when properties become available in your desired search
            </Text>
            <form ref={form} onSubmit={formik.handleSubmit}>
                <Stack gap={2} sx={{ mt: 2 }}>
                    <TextInput
                        label={'Full name'}
                        placeholder={'Enter Full Name'}
                        size={'lg'}
                        errorText={
                            formik.touched.fullName && formik.errors?.fullName
                                ? formik.errors.fullName
                                : undefined
                        }
                        type="text"
                        name="fullName"
                        value={formik.values.fullName}
                        onBlur={formik.handleBlur}
                        onChange={handleChange}
                        sxLabel={{ fontWeight: 600 }}
                    />
                </Stack>
                <Stack gap={2} sx={{ mt: 2 }}>
                    <TextInput
                        label={'Phone Number'}
                        placeholder={'Enter Phone Number'}
                        size={'lg'}
                        errorText={
                            formik.touched.phoneNumber &&
                            formik.errors?.phoneNumber
                                ? formik.errors.phoneNumber
                                : undefined
                        }
                        type="text"
                        name="phoneNumber"
                        value={formik.values.phoneNumber}
                        onBlur={formik.handleBlur}
                        onChange={handleChange}
                        sxLabel={{ fontWeight: 600 }}
                    />
                </Stack>
                <Stack gap={2} sx={{ mt: 2 }}>
                    <TextInput
                        label={'Preferred location'}
                        placeholder={'Enter Preferred Location'}
                        size={'lg'}
                        errorText={
                            formik.touched.preferredLocation &&
                            formik.errors?.preferredLocation
                                ? formik.errors.preferredLocation
                                : undefined
                        }
                        type="text"
                        name="preferredLocation"
                        value={formik.values.preferredLocation}
                        onBlur={formik.handleBlur}
                        onChange={handleChange}
                        sxLabel={{ fontWeight: 600 }}
                    />
                </Stack>
                <Stack gap={2} sx={{ mt: 2 }}>
                    <TextInput
                        label={'Budget'}
                        placeholder={'Enter budget range'}
                        size={'lg'}
                        errorText={
                            formik.touched.budget && formik.errors?.budget
                                ? formik.errors.budget
                                : undefined
                        }
                        type="text"
                        name="budget"
                        value={formik.values.budget}
                        onBlur={formik.handleBlur}
                        onChange={handleChange}
                        sxLabel={{ fontWeight: 600 }}
                    />
                </Stack>
                <Stack gap={2} sx={{ mt: 2 }}>
                    <Typography
                        level={'body-sm'}
                        sx={{ mb: -1.8, fontWeight: 600 }}
                    >
                        Choose property type
                    </Typography>
                    <Select
                        size="lg"
                        name="propertyType"
                        value={formik.values.propertyType}
                        placeholder="Choose type of property"
                        onChange={(_, value) =>
                            formik.setFieldValue('propertyType', value)
                        }
                        sx={{
                            '& button:focus': {
                                outline: 'none',
                            },
                        }}
                    >
                        {propertyTypes.map((prop, index) => {
                            return (
                                <Option key={index} value={prop}>
                                    {prop}
                                </Option>
                            )
                        })}
                    </Select>
                    {formik.touched.propertyType &&
                        formik.errors?.propertyType && (
                            <Typography
                                level={'body-xs'}
                                sx={{ color: colors.red, mb: 1 }}
                            >
                                {formik.errors.propertyType}
                            </Typography>
                        )}
                </Stack>
                {response && (
                    <Box my={2}>
                        <Typography
                            sx={{
                                color: error ? colors.red : colors.secondary,
                            }}
                        >
                            {response}
                        </Typography>
                    </Box>
                )}
                <Button
                    fullWidth
                    loading={loading}
                    disabled={error || loading || !formik.isValid}
                    onClick={() => formik.handleSubmit()}
                    sx={{
                        mt: 2,
                        py: 1,
                        fontSize: 18,
                        borderWidth: 0,
                        fontWeight: 500,
                        '&:hover': {
                            backgroundColor: colors.buttonHover,
                            border: 0,
                        },
                        '&:focus': {
                            outline: 0,
                        },
                    }}
                >
                    Submit
                </Button>
            </form>
        </Box>
    )
}
