export enum PropertyType {
    RESIDENTIAL = 'residential',
    COMMERCIAL = 'commercial',
    UNKNOWN = 'unknown',
}

export enum ResidentialType {
    Townhouse = 'townhouse',
    House = 'house',
    Apartment = 'apartment',
}

export enum PropertySpaceType {
    SHARED_SPACE = 'shared_space',
    ENTIRE_SPACE = 'entire_space',
}

export enum PurposeOfListingType {
    RENT = 'rent',
    SHORT_STAY = 'short_stay',
    SELL = 'sell',
}
