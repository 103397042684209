import * as React from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Sheet,
    Stack,
    Typography,
    useTheme,
} from '@mui/joy'
import { ChatBubble } from '@/src/pages/secure/messaging/components/chat-bubble'
import { MessageInput } from '@/src/pages/secure/messaging/components/message-input'
import { MessagesPaneHeader } from '@/src/pages/secure/messaging/components/messages-pane-header'
import { useUserChatController } from '@/services/use-user-messaging'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { CommunicationSignalingClient } from '@azure/communication-signaling'
import { createClientLogger } from '@azure/logger'
import { colors } from '@/utilities/colors'

export function MessagesPane() {
    const [textAreaValue, setTextAreaValue] = React.useState<string>('')
    const {
        initiateNewConversation,
        setInitiateNewConversation,
        isLoadingMessages,
        sendMessage,
        createNewConversationAfterTokenExpired,
        selectedConversation,
        conversations,
        token,
        ownerName,
    } = useUserChatController()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const authController = useGetAuthenticatedUser()
    const theme = useTheme()
    const isTablet = theme.breakpoints.down('lg')

    async function handleCreateNewCionversation(): Promise<void> {
        setLoading(true)
        try {
            if (authController.user) {
                await createNewConversationAfterTokenExpired()
                setInitiateNewConversation(false)
                setLoading(false)
            }
        } catch (error) {
            console.error('Error creating conversation:', error)
            setError(true)
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (token) {
            const tokenCredential = new AzureCommunicationTokenCredential(token)
            const logger = createClientLogger('communication-signaling')
            const signalingClient = new CommunicationSignalingClient(
                tokenCredential,
                logger
            )
            signalingClient.start()
            signalingClient.on('typingIndicatorReceived', (payload) => {
                console.log(payload)
                console.log('Notification typingIndicatorReceived!')
            })
        }
    }, [textAreaValue])
    // Update conversations state to indicate typing

    function handleSendMessage(textValue: string) {
        if (!authController.user?.id) {
            return
        }
        sendMessage(textValue)
            .then(() => {})
            .catch((error) => {
                console.error('Error sending message:', error)
            })
        setTextAreaValue('')
    }

    return (
        <Sheet
            sx={{
                height: {
                    xs: 'calc(100dvh - var(--Header-height))',
                    lg: '100dvh',
                },
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.level1',
            }}
        >
            {selectedConversation && !isTablet && (
                <MessagesPaneHeader
                    sender={{
                        first_name: 'Test',
                        last_name: 'User',
                    }}
                />
            )}
            {isLoadingMessages ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100vh',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            minHeight: 0,
                            px: 2,
                            py: 3,
                            overflowY: 'scroll',
                            flexDirection: 'column-reverse',
                        }}
                    >
                        {initiateNewConversation ? (
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'grid',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        bgcolor: colors.white,
                                        py: '2rem',
                                        gap: '1rem',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography>
                                        Ooopss!!! Looks like your conversation
                                        with{' '}
                                        {ownerName ? (
                                            <b>{ownerName}</b>
                                        ) : (
                                            'that user'
                                        )}{' '}
                                        expired.
                                    </Typography>
                                    {error && (
                                        <Typography sx={{ color: colors.red }}>
                                            An unexpected error occured creating
                                            new conversation. Please refresh
                                            page and try again
                                        </Typography>
                                    )}
                                    <Button
                                        loading={loading}
                                        sx={{ maxWidth: '18rem' }}
                                        onClick={() =>
                                            handleCreateNewCionversation()
                                        }
                                    >
                                        Create New Conversation
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <Stack spacing={2} justifyContent="flex-end">
                                    {conversations.map((message) => {
                                        const isYou =
                                            message.metadata.id ===
                                            authController.user?.id
                                        return (
                                            <Stack
                                                key={message.id}
                                                direction="row"
                                                spacing={2}
                                                flexDirection={
                                                    isYou
                                                        ? 'row-reverse'
                                                        : 'row'
                                                }
                                            >
                                                <ChatBubble
                                                    variant={
                                                        isYou
                                                            ? 'sent'
                                                            : 'received'
                                                    }
                                                    {...message}
                                                />
                                                {message.metadata.isTyping && (
                                                    <Typography
                                                        sx={{
                                                            color: colors.gray,
                                                        }}
                                                    >
                                                        {`${message.senderDisplayName} is typing...`}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        )
                                    })}
                                </Stack>
                            </>
                        )}
                    </Box>
                    {!initiateNewConversation && (
                        <MessageInput
                            value={textAreaValue}
                            onSetValue={setTextAreaValue}
                            onSubmit={() => handleSendMessage(textAreaValue)}
                        />
                    )}
                </>
            )}
        </Sheet>
    )
}
