import { useNavigate } from 'react-router-dom'
import { Typography, Box, Divider, Button } from '@mui/joy'
import { colors } from '@/utilities/colors.ts'
import check from '@/src/pages/home/components/subscription/assets/images/Check_fill.png'
import diamond from '@/src/pages/home/components/subscription/assets/images/diamond.png'

export function LandlordsSubscription() {
    const navigate = useNavigate()
    const landlordsFeatures = {
        basic: [
            'Free Property Listings: List your property and reach potential renters.',
            '24/7 Customer Support: Help anytime you need it',
            'Exclusive Listings: Access to the latest property listings.',
        ],
        pro: [
            'Interior Virtual Tour: High-quality interior virtual tours of your property.',
            'Exterior Virtual Tour: Get exterior virtual tour of your property.',
            'Free Property Listings: List many properties and reach potential renters.',
            'Unlimited number of virtual tour viewing.',
            '24/7 Customer Support: Help anytime you need it.',
            'Exclusive Community Access: Get access to join our private group with 250+ renters, landlords and agents.',
        ],
    }
    return (
        <Box
            sx={{
                marginBottom: 5,
                display: 'flex',
                alignItems: 'start',
                justifyItems: {
                    xs: 'start',
                    sm: 'center',
                },
                width: '100%',
                paddingTop: 8,
                gap: 4,
                flexDirection: {
                    xs: 'column',
                    sm: 'row',
                },
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: '90%',
                        sm: '40%',
                    },
                    bgcolor: 'white',
                    borderRadius: 10,
                    padding: 3,
                }}
            >
                <Box mb={2}>
                    <Box
                        mb={2}
                        sx={{
                            textAlign: 'center',
                            bgcolor: '#F1F1F1',
                            width: { xs: '20%', sm: '35%', md: '28%' },
                            padding: 1,
                            borderRadius: 5,
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                color: colors.footerTextColor,
                            }}
                        >
                            BASIC
                        </Typography>
                    </Box>

                    <Typography>
                        Start with our basic plan to experience the essential
                        feature of Openprop
                    </Typography>
                </Box>
                <Divider />
                <Box>
                    <Box display={'flex'} alignItems={'end'}>
                        <Typography
                            sx={{
                                fontSize: { xs: 40, sm: 50, md: 70 },
                                fontWeight: 700,
                                my: 3,
                            }}
                        >
                            FREE
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    mt={4}
                    mb={4}
                >
                    {landlordsFeatures.basic.map((feature, index) => {
                        const feat = feature.split(':')
                        return (
                            <Box
                                key={index}
                                display={'flex'}
                                alignItems={'start'}
                                gap={2}
                            >
                                <img src={check} alt="flex" />
                                <Typography>
                                    <b>{feat[0]}</b>: {feat[1]}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            <Box
                borderRadius={10}
                padding={3}
                sx={{
                    width: {
                        xs: '90%',
                        sm: '40%',
                    },
                    background:
                        'linear-gradient(180deg, rgba(239, 161, 45, 0.15) 10.12%, rgba(239, 161, 45, 0.05) 93.26%)',
                }}
            >
                <Box mb={2}>
                    <Box
                        mb={2}
                        textAlign={'center'}
                        bgcolor={'white'}
                        width={'28%'}
                        padding={1}
                        borderRadius={5}
                    >
                        <Box display={'flex'} gap={0.5}>
                            <img src={diamond} alt="diamond" />
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    color: colors.footerTextColor,
                                }}
                            >
                                PRO
                            </Typography>
                        </Box>
                    </Box>
                    <Typography>
                        Maximize your property's reach with our tailored listing
                        services.
                    </Typography>
                </Box>
                <Divider></Divider>
                <Box mt={2} mb={4}>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography
                            sx={{
                                fontSize: { xs: 40, sm: 72 },
                                fontWeight: 700,
                                marginBottom: '-15px',
                            }}
                        >
                            2%
                        </Typography>
                        <Typography sx={{ fontWeight: 500 }}>
                            of amount listed on property
                        </Typography>
                    </Box>
                </Box>
                <Divider></Divider>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    mt={4}
                    mb={4}
                >
                    {landlordsFeatures.pro.map((feature, index) => {
                        const feat = feature.split(':')
                        return (
                            <Box
                                key={index}
                                display={'flex'}
                                alignItems={'start'}
                                gap={2}
                            >
                                <img src={check} alt="flex" />
                                <Typography>
                                    <b>{feat[0]}</b>: {feat[1]}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
                <Button
                    fullWidth
                    onClick={() => navigate('/secure/properties/create')}
                >
                    Subscribe
                </Button>
            </Box>
        </Box>
    )
}
