import { useMediaQuery, useTheme, Typography, Container } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Scrollbar, A11y } from 'swiper/modules'
import { colors } from '@/src/utilities/colors.ts'
import { PropertyCard } from '@/src/components/property-card/index.tsx'
import { useGetLatestProperties } from '@/src/services/use-get-properties/index.tsx'
import { PropertyCardLoading } from '@/components/property-card/components/property-card-loading'

import 'swiper/css'
import 'swiper/css/navigation'
import { ViewMoreCard } from '@/components/view-more-card'
import { Box } from '@mui/joy'
import { Link } from 'react-router-dom'

export function PropertyList() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))
    const isSmall = useMediaQuery(theme.breakpoints.down('xs'))
    const properties = useGetLatestProperties()
    const propertyList = (properties.data || []).slice(0, 6)

    if (properties.isError) {
        return 'Error fetching properties'
    }

    return (
        <Container maxWidth={'xl'}>
            <Typography
                variant="h2"
                sx={{
                    fontWeight: 600,
                    fontSize: 22,
                    color: colors.footerTextColor,
                    mt: 3,
                    textAlign: { xs: 'center', sm: 'center' },
                    display: { xs: 'none', md: 'block' },
                }}
            >
                New listings
            </Typography>
            {properties.isPending ? (
                <PropertyCardLoading length={isMobile ? 1 : 4} />
            ) : (
                <Box>
                    <Box
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            alignItems: 'center',
                        }}
                        mt={3}
                        justifyContent="space-between"
                        padding={0}
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 600,
                                fontSize: 22,
                                color: colors.footerTextColor,
                            }}
                        >
                            New listings
                        </Typography>
                        <Link to="/properties">
                            <Typography
                                sx={{
                                    color: colors.primary,
                                    fontSize: 20,
                                }}
                            >
                                View All
                            </Typography>
                        </Link>
                    </Box>
                    <Swiper
                        modules={[Navigation, Scrollbar, A11y]}
                        navigation
                        scrollbar={{ draggable: true }}
                        style={{
                            marginBottom: 5,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 2,
                            paddingRight: 2,
                        }}
                        spaceBetween={10} //isSmall ? 6 : isTablet ? 10 : isMobile ? 30 : 40}
                        slidesPerView={
                            isSmall ? 1 : isTablet ? 1 : isMobile ? 1 : 4
                        }
                        autoplay={true}
                    >
                        {propertyList.map((property) => (
                            <SwiperSlide key={property.id}>
                                <PropertyCard property={property} noShadow />
                            </SwiperSlide>
                        ))}
                        <SwiperSlide>
                            <ViewMoreCard />
                        </SwiperSlide>
                    </Swiper>
                </Box>
            )}
        </Container>
    )
}
