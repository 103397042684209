import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const schema = z.object({
    fullName: z.string({ required_error: 'Enter your full name' }),
    phoneNumber: z
        .string({
            required_error: 'Phone number is required',
            invalid_type_error: 'Please enter a valid phone number',
        })
        .regex(
            /^\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
            'Invalid Phone Number'
        ),

    preferredLocation: z.string({
        required_error: 'Preferred location is required',
    }),
    budget: z
        .string({
            required_error: 'Please provide your budget',
        })
        .regex(
            /^\d+(-\d+)?$/,
            'Budget must be a number or a range in the format 100-200, 500, etc.'
        ),
    propertyType: z.string({ required_error: 'Please choose a property type' }),
})

export const waitlistSchema = toFormikValidationSchema(schema)
