import React from 'react'
import { Box } from '@mui/joy'
import VirtualTourLimitBg from '@/assets/images/tour-limit-blur-image.png'
import { Typography } from '@mui/material'
import { colors } from '@/utilities/colors'
import analytics from '@/utilities/analytics.ts'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { ShowVirtualTourCard } from '@/src/pages/properties/components/virtual-tour-limit/components/show-virtual-tour-card'

export function VirtualTourLimit(props: {
    src?: string
    isVisible?: boolean
    userId?: string
}) {
    const { isPending } = useGetAuthenticatedUser()
    const isVisible = props.src && props.isVisible
    React.useEffect(() => {
        const handleClick = () => {
            if (isVisible && props.userId) {
                analytics.track('virtual_tour_clicked', {
                    virtual_tour_click_user_id: props.userId,
                })
                window.parent.postMessage(
                    { type: 'iframe-clicked', source: 'VirtualTourLimit' },
                    '*'
                )
            }
        }

        // Attach the click event listener
        document.addEventListener('click', handleClick)

        return () => {
            // Cleanup the event listener on unmount
            document.removeEventListener('click', handleClick)
        }
    }, [])

    if (isPending) {
        return <Typography>Please wait ...</Typography>
    }

    if (!isVisible) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingX: { xs: 1, md: 4 },
                    paddingY: { xs: 6, md: 8 },
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${VirtualTourLimitBg})`,
                }}
            >
                <Box
                    textAlign={'center'}
                    gap={2}
                    sx={{
                        color: colors.footerTextColor,
                        border: '1px solid white',
                        width: { xs: '90%', md: '50%' },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                        borderRadius: 10,
                        padding: 2,
                        bgcolor: colors.white,
                    }}
                >
                    <ShowVirtualTourCard />
                </Box>
            </Box>
        )
    }

    return (
        <iframe
            width="100%"
            height="500"
            src={props.src}
            allowFullScreen
            allow="xr-spatial-tracking"
        />
    )
}
