import { Typography, Box, Divider } from '@mui/joy'
import { colors } from '@/utilities/colors.ts'
import check from '@/src/pages/home/components/subscription/assets/images/Check_fill.png'
import diamond from '@/src/pages/home/components/subscription/assets/images/diamond.png'
import { formatPrice } from '@/utilities/format-price.ts'
import { PayWithPayStack } from '@/components/pay-with-paystack'
import { queryClient } from '@/utilities/react-query-client.ts'
import { useToastController } from '@/services/use-toast'

export function RentersSubscription() {
    const toast = useToastController()
    const rentersFeatures = {
        basic: [
            'Interior Virtual Tour: Explore inside of homes with immersive virtual tours Limited to 2 tours for registered users.',
            'Exclusive Listings: Access to the latest property listings.',
            '24/7 Support: Help anytime you need it.',
        ],
        pro: [
            'Interior Virtual Tour unlimited: Explore inside of homes with immersive tours.',
            'Exterior Virtual Tour unlimited: Check out the outdoor surroundings of properties.',
            'Exclusive Listings: Access to the latest property listings.',
            '24/7 Customer Support: Help anytime you need it.',
            'Exclusive Community: Get access to join our private group with over 250+ renters, landlords and agents.',
        ],
    }

    async function onSuccess() {
        toast.onOpen(
            'Your payment is being processed. Please wait a moment while we activate your pro subscription.',
            'success'
        )
        await queryClient.invalidateQueries()
    }
    return (
        <Box
            sx={{
                marginBottom: 5,
                display: 'flex',
                alignItems: 'start',
                justifyItems: {
                    xs: 'start',
                    sm: 'center',
                },
                width: '100%',
                paddingTop: 8,
                gap: 4,
                flexDirection: {
                    xs: 'column',
                    sm: 'row',
                },
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: '90%',
                        sm: '40%',
                    },
                    bgcolor: 'white',
                    borderRadius: 10,
                    padding: 3,
                }}
            >
                <Box mb={2}>
                    <Box
                        mb={2}
                        sx={{
                            textAlign: 'center',
                            bgcolor: '#F1F1F1',
                            width: '20%',
                            padding: 1,
                            borderRadius: 5,
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                color: colors.footerTextColor,
                            }}
                        >
                            BASIC
                        </Typography>
                    </Box>

                    <Typography>
                        Start with our basic plan to experience the essential
                        feature of Openprop
                    </Typography>
                </Box>
                <Divider />
                <Box>
                    <Box display={'flex'} alignItems={'end'}>
                        <Typography
                            sx={{
                                fontSize: { xs: 40, sm: 50, md: 70 },
                                fontWeight: 700,
                                my: 3,
                            }}
                        >
                            FREE
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    mt={4}
                    mb={4}
                >
                    {rentersFeatures.basic.map((feature, index) => {
                        const feat = feature.split(':')
                        return (
                            <Box
                                key={index}
                                display={'flex'}
                                alignItems={'start'}
                                gap={2}
                            >
                                <img src={check} alt="flex" />
                                <Typography>
                                    <b>{feat[0]}</b>: {feat[1]}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            <Box
                sx={{
                    width: {
                        xs: '90%',
                        sm: '40%',
                    },
                    background:
                        'linear-gradient(180deg, rgba(239, 161, 45, 0.15) 10.12%, rgba(239, 161, 45, 0.05) 93.26%)',
                    borderRadius: 10,
                    padding: 3,
                }}
            >
                <Box mb={2}>
                    <Box
                        mb={2}
                        sx={{
                            textAlign: 'center',
                            bgcolor: 'white',
                            width: { xs: '20%', sm: '35%', md: '28%' },
                            padding: 1,
                            borderRadius: 5,
                        }}
                    >
                        <Box display={'flex'} gap={0.5}>
                            <img src={diamond} alt="diamond" />
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    color: colors.footerTextColor,
                                }}
                            >
                                PRO
                            </Typography>
                        </Box>
                    </Box>
                    <Typography>
                        Discover your next home with our comprehensive virtual
                        tours.
                    </Typography>
                </Box>
                <Divider></Divider>
                <Box mt={2} mb={4}>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: { xs: 40, sm: 50, md: 70 },
                                fontWeight: 700,
                            }}
                        >
                            {formatPrice(25)}
                            <Typography
                                level={'body-md'}
                                sx={{ fontWeight: 500 }}
                            >
                                /Mo.
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
                <Divider></Divider>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    mt={4}
                    mb={4}
                >
                    {rentersFeatures.pro.map((feature, index) => {
                        const feat = feature.split(':')
                        return (
                            <Box
                                key={index}
                                display={'flex'}
                                alignItems={'start'}
                                gap={2}
                            >
                                <img src={check} alt="flex" />
                                <Typography>
                                    {' '}
                                    <b>{feat[0]}</b>: {feat[1]}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
                <PayWithPayStack
                    amount={25}
                    label="Subscribe"
                    onSuccess={onSuccess}
                />
            </Box>
        </Box>
    )
}
