import { useMediaQuery } from '@mui/material'
import { Button, Box, Grid, useTheme } from '@mui/joy'
import { PropertyCard } from '@/components/property-card'
import { Property } from '@/src/services/use-get-properties/types.ts'
import { Footer } from '@/src/pages/properties/components/footer'
import { PropertyCardLoading } from '@/components/property-card/components/property-card-loading'

interface IPropertyListProps {
    properties: Property[]
    paginationComponent?: React.ReactNode
    isLoading: boolean
}

export function PropertyList(props: IPropertyListProps) {
    if (props.isLoading) {
        return (
            <Box mx={3}>
                <PropertyCardLoading length={6} md={6} />
            </Box>
        )
    }

    return (
        <Box overflow={'hidden'} className={'no-scrollbar'}>
            <Grid container spacing={2} px={2} mb={4}>
                {props.properties.map((property) => (
                    <Grid xs={12} sm={6} md={6} lg={6} key={property.id}>
                        <PropertyCard noShadow property={property} />
                    </Grid>
                ))}
                <Box mb={2} />
            </Grid>
            {props.paginationComponent}
            <Box id="footer">
                <Footer />
            </Box>
        </Box>
    )
}

interface IViewOnMapProps {
    isMapView: boolean
    setIsMapView: (value: boolean) => void
}

export function ViewOnMap(props: IViewOnMapProps) {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))

    if (!isTablet) {
        return null
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                bottom: 90,
                width: '100%',
                position: 'fixed',
                zIndex: 999,
            }}
        >
            <Button onClick={() => props.setIsMapView(!props.isMapView)}>
                {props.isMapView ? 'View list' : 'View on map'}
            </Button>
        </Box>
    )
}
